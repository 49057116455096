import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PT from 'prop-types';

import CN from './Button.module.scss';

const Button = ({ to = '#', link, external, onClick, className = '', children, disabled, variation = 'filled' }) => {
    const buttonClassnames = classNames(CN[variation], CN.button, className);
    return (
        <>
            {link ? (
                <Link
                    to={to}
                    onClick={onClick}
                    target={external ? '_blank' : undefined}
                    rel={external ? 'noopener noreferrer' : undefined}
                    className={className}
                >
                    {children}
                </Link>
            ) : (
                <button disabled={disabled} type="button" onClick={onClick} className={buttonClassnames}>
                    {children}
                </button>
            )}
        </>
    );
};

Button.propTypes = {
    className: PT.string,
    children: PT.node.isRequired,
    external: PT.bool,
    link: PT.bool,
    onClick: PT.func,
    to: PT.string,
    disabled: PT.bool,
    variation: PT.string,
};

export default Button;
