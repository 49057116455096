import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'context/LocationContext';

import NAV from 'core/nav.conf';

import DateFrom from './DatesAutocomplete/DateFrom.component';
import DateTo from './DatesAutocomplete/DateTo.component';
import DesksAutocomplete from './DesksAutocomplete/DesksAutocomplete.component';
import EmployeesAutocomplete from './EmployeesAutocomplete/EmployeesAutocomplete.component';
import ExportButton from './ExportButton/ExportButton.compoenent';
import LocationAutocomplete from './LocationAutocomplete/LocationAutocomplete.component';
import OfficesAutocomplete from './OfficesAutocomplete/OfficesAutocomplete.component';
import ReportsTable from './ReportsTable/ReportsTable.component';
import StatusAutocomplete from './StatusAutocomplete/StatusAutocomplete.component';

import CN from '../Reports/Reports.module.scss';

const Reports = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location) {
            navigate(NAV.home, { replace: true });
        }
    }, [location, navigate]);

    return (
        <section className={CN.contents}>
            <div className={CN.filtersContainer}>
                <EmployeesAutocomplete />
                <DateFrom />
                <DateTo />
                <LocationAutocomplete />
                <OfficesAutocomplete />
                <DesksAutocomplete />
                <StatusAutocomplete />
                <ExportButton />
            </div>
            <ReportsTable />
        </section>
    );
};

Reports.propTypes = {};

export default Reports;
