const RADIUS = 70;
const MARGIN = 5;

const generateAvatar = (displayName) => {
    const initials = displayName
        .split(' ')
        .map(function (str) {
            return str ? str[0].toUpperCase() : '';
        })
        .join('');
    const canvas = document.createElement('canvas');

    canvas.width = RADIUS * 2 + MARGIN * 2;
    canvas.height = RADIUS * 2 + MARGIN * 2;

    // Get the drawing context
    const ctx = canvas.getContext('2d');

    ctx.beginPath();
    ctx.arc(RADIUS + MARGIN, RADIUS + MARGIN, RADIUS, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.fillStyle = '#66bafd';
    ctx.fill();
    ctx.fillStyle = 'white';
    ctx.font = 'bold 60px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(initials, RADIUS + 5, (RADIUS * 4) / 3 + MARGIN);

    return canvas.toDataURL();
};

export default generateAvatar;
