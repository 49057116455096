import { useCallback, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useLocation } from 'context/LocationContext.js';
import { useMyReservation } from 'context/MyReservationContext.js';
import { useReservation } from 'context/ReservationContext.js';
import { useToastDispatch } from 'context/ToastContext.js';
import { sortBy } from 'lodash/fp';

import ReservationsTable from './ReservationsTable.component.jsx';

import 'react-tabs/style/react-tabs.css';
import CN from './Reservations.module.scss';

const TAB = {
    tab1: 0,
    tab2: 1,
};

const ReservationsAdmin = () => {
    const [selectedIndex, setSelectedIndex] = useState(1);
    const selectedLoc = useLocation();
    const toastDispatch = useToastDispatch();

    useEffect(() => {
        !selectedLoc ? setSelectedIndex(TAB.tab2) : setSelectedIndex(TAB.tab1);
    }, [selectedLoc]);

    const selectTab = useCallback(
        (index) => {
            if (!selectedLoc && index === 0) {
                toastDispatch({
                    type: 'error',
                    message: 'No location selected. Please select a location first.',
                    duration: 4000,
                });
                return;
            }
            setSelectedIndex(index);
        },
        [toastDispatch, selectedLoc],
    );

    let allReservations = useReservation() || [];

    let myReservations = useMyReservation();

    allReservations = sortBy(['date', 'deskCode'], allReservations);

    myReservations = sortBy(['date', 'deskCode'], myReservations);

    return (
        <Tabs selectedIndex={selectedIndex} onSelect={(index) => selectTab(index)} className={CN.fullWidth}>
            <TabList className={`${CN.textLeft} ${CN.tabs} ${CN.p25}`}>
                <Tab className={`${CN.tabstyle}`} disabledClassName={CN.disabled} selectedClassName={CN.tabstyleActive}>
                    All Desks
                </Tab>
                <Tab className={CN.tabstyle} selectedClassName={CN.tabstyleActive}>
                    My Desks
                </Tab>
            </TabList>
            <TabPanel>
                <ReservationsTable adminView reservations={allReservations} />
            </TabPanel>
            <TabPanel>
                <ReservationsTable reservations={myReservations} />
            </TabPanel>
        </Tabs>
    );
};

ReservationsAdmin.propTypes = {};

export default ReservationsAdmin;
