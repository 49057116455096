import { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { sortBy } from 'lodash/fp';
import PT from 'prop-types';

import Loader from 'components/common/Loader/Loader.component.jsx';
import ReserveTableRow from 'components/layout/Reserve/ReserveTableRow.component.jsx';
import useGetOfficeReservations from 'hooks/api/Reservations/useGetOfficeReservations.hook.js';
import isArrayEmpty from 'utils/isArrayEmpty.util.js';

import CN from './ReserveTable.module.scss';

const ReserveTable = ({ location, selectedOffice, selectedDates }) => {
    const { value: officeReservations, loading, getOfficeReservations } = useGetOfficeReservations();

    const [filteredOfficeReservations, setFilteredOfficeReservations] = useState({});

    const formatedDates = useMemo(
        () =>
            !isArrayEmpty(selectedDates)
                ? selectedDates?.map((date) => {
                      return format(date, 'yyyy-MM-dd');
                  })
                : [],
        [selectedDates],
    );

    const hasRecords = filteredOfficeReservations && Object.keys(filteredOfficeReservations).length > 0;

    const filterRes = useCallback((reservations) => {
        return sortBy(
            ['date', 'desk.code'],
            reservations?.data?.filter((reservation) => reservation.status !== 'AUTO_CANCELLED'),
        );
    }, []);

    useEffect(
        () =>
            void (
                !isArrayEmpty(formatedDates) &&
                location &&
                selectedOffice &&
                getOfficeReservations(location, selectedOffice, formatedDates)
            ),
        [formatedDates, getOfficeReservations, location, selectedOffice],
    );

    useEffect(() => {
        !isArrayEmpty(formatedDates) && officeReservations
            ? setFilteredOfficeReservations(filterRes(officeReservations))
            : setFilteredOfficeReservations();
    }, [filterRes, officeReservations, formatedDates]);

    return (
        <div className={`${CN.p35} ${CN.scroll}`}>
            {loading ? (
                <Loader className={CN.reserveTableLoader} />
            ) : hasRecords ? (
                <table className={`${CN.textLeft} ${CN.table} ${CN['table-borderless']}`}>
                    <thead>
                        <tr>
                            <td className={`${CN.fz16} ${CN.gray}`}>Employee</td>
                            <td className={`${CN.fz16} ${CN.gray}`}>Desk</td>
                            <td className={`${CN.fz16} ${CN.gray}`}>Date</td>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOfficeReservations.map(({ id, employee, desk, date }) => (
                            <ReserveTableRow key={id} employee={employee} desk={desk} date={date} />
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>
                    {selectedDates?.length === 0
                        ? 'No date(s) are selected'
                        : 'There are no reservations for the selected period.'}
                </p>
            )}
        </div>
    );
};

ReserveTable.defaultProps = {
    options: {},
};

ReserveTable.propTypes = {
    options: PT.shape({
        data: PT.array,
    }),
    location: PT.string,
    selectedOffice: PT.string,
    selectedDates: PT.array,
    startDate: PT.instanceOf(Date),
    endDate: PT.instanceOf(Date),
};

export default ReserveTable;
