import PT from 'prop-types';

import loginLogo from 'assets/login-logo.svg';

const LoginLogo = ({ className }) => <img className={className} src={loginLogo} alt="Login Logo" />;

LoginLogo.propTypes = {
    className: PT.string,
};

export default LoginLogo;
