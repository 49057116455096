import logger from './logger.util.js';

/**
 *
 * Catches rejections from promises and writes them to the console,
 * so they aren't ignored
 *
 */
const unignore = (tag, promise) => {
    const L = logger(tag);
    promise.catch((reason) => L.warn(tag, reason));
    return promise;
};

export default unignore;
