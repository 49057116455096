import { useMemo } from 'react';
import { useAsyncFn } from 'react-use';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';
import unignore from 'utils/unignore.util.js';

const TYPE = 'Json';
const URI = `${C.baseBeUrl}/employees/loggedIn`;

const useEmployeesLoggedIn = () => {
    const { token } = useAccessToken(C.apiType.backend);

    const params = new URLSearchParams({});

    const [{ error, value, loading }, employeeLoggedIn] = useAsyncFn(async () => {
        if (!token) {
            return;
        }

        return await unignore('useEmployeesLoggedIn', fetchFromBe({ uri: URI, token, type: TYPE, params }));
    }, [token]);

    return useMemo(
        () => ({
            employeeLoggedIn,
            employeeLoggedInError: error,
            employeeLoggedInResult: value,
            employeeLoggenInLoading: loading,
        }),
        [employeeLoggedIn, error, value, loading],
    );
};

export default useEmployeesLoggedIn;
