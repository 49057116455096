import { useMemo } from 'react';
import { useAsync } from 'react-use';

import useGetRole from 'hooks/useGetRole.hook.js';
import logger from 'utils/logger.util';

const L = logger('useIsAdmin');

const ADMIN_ROLE = 'Application.Admin';

const useIsAdmin = () => {
    const { error, role } = useGetRole();

    if (error) {
        L.warn(error);
    }

    const { loading, value: admin } = useAsync(async () => {
        // Todo fix this => ?
        if (!role) {
            return false;
        }
        if (role.includes(ADMIN_ROLE)) {
            return true;
        }
    }, [role]);

    return useMemo(() => ({ admin: loading ? undefined : admin, loading, error }), [admin, loading, error]);
};

export default useIsAdmin;
