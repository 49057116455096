import { useCallback } from 'react';
import { DayPicker } from 'react-day-picker';
import { eachWeekendOfMonth, format } from 'date-fns';
import PT from 'prop-types';

import useComponentVisible from 'hooks/useComponentVisible.hook';

import CN from './DateAutocomplete.module.scss';

const DEFAULT_MONTH = new Date();
const HIDDEN_WEEKENDS = eachWeekendOfMonth(DEFAULT_MONTH);

const DateAutocomplete = ({ value, onSelect, placeholder, disabled }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const formatWeekDays = (date) => format(date, 'iii');

    const handleToggleOptions = useCallback(() => {
        setIsComponentVisible(!isComponentVisible);
    }, [isComponentVisible, setIsComponentVisible]);

    const handleSearch = useCallback(
        (e) => {
            onSelect(e.target.value);
        },
        [onSelect],
    );

    const handleSelect = useCallback(
        (date) => {
            const formatted = format(date, 'dd/MM/yyyy');
            onSelect(formatted);
            handleToggleOptions();
        },
        [onSelect, handleToggleOptions],
    );

    return (
        <div ref={ref} className={CN.container}>
            <input value={value} onChange={handleSearch} placeholder={placeholder} onClick={handleToggleOptions} />
            {isComponentVisible && (
                <DayPicker
                    mode="single"
                    selected={value}
                    hidden={HIDDEN_WEEKENDS}
                    onSelect={handleSelect}
                    className={CN.dropdown}
                    disabled={disabled || []}
                    formatters={{
                        formatWeekdayName: formatWeekDays,
                    }}
                />
            )}
        </div>
    );
};

DateAutocomplete.propTypes = {
    value: PT.string.isRequired,
    onSelect: PT.func.isRequired,
    placeholder: PT.string,
    disabled: PT.array,
};

export default DateAutocomplete;
