import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import PT from 'prop-types';

import conf from 'core/global.conf.js';
import loggerCallback from 'utils/mslog.util.js';

const { baseFeUrl, clientId, baseMsUrl, tenantId } = conf;

const msal = new PublicClientApplication({
    auth: {
        clientId: clientId,
        authority: `${baseMsUrl}${tenantId}`,
        redirectUri: baseFeUrl,
        postLogoutRedirectUri: baseFeUrl,
    },

    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },

    system: {
        loggerOptions: {
            loggerCallback,
        },
    },
});

// TODO change point of set Active Account
msal.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msal.setActiveAccount(account);
    }
});

const Auth = ({ children }) => {
    return <MsalProvider instance={msal}>{children}</MsalProvider>;
};

Auth.propTypes = {
    children: PT.node,
};

export default Auth;
