import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'context/LocationContext';
import { useToastDispatch } from 'context/ToastContext';
import { format } from 'date-fns';

import Button from 'components/common/Button/Button.component';
import ConfirmReservationModal from 'components/common/ConfirmReservationModal/ConfirmReservationModal.component.jsx';
import CustomDatePicker from 'components/common/DatePicker/CustomDatePicker.component.jsx';
import DeskPicker from 'components/common/DeskPicker/DeskPicker.component.jsx';
import Loader from 'components/common/Loader/Loader.component.jsx';
import MapPopUpReserve from 'components/common/MapPopUpReserve/MapPopUpReserve.component.jsx';
import OfficePicker from 'components/common/OfficePicker/OfficePicker.component.jsx';
import CN from 'components/layout/Reserve/Reserve.module.scss';
import ReserveTable from 'components/layout/Reserve/ReserveTable.component.jsx';
import NAV from 'core/nav.conf';
import useEmployeesLoggedIn from 'hooks/api/Employees/useEmployesLoggedIn.hook.js';
import useGetLocations from 'hooks/api/Locations/useGetLocations.hook.js';
import useGetMyReservations from 'hooks/api/MyReservations/useGetMyReservations.hook';
import currentOffice from 'utils/currentOffice.util.js';
import isArrayEmpty from 'utils/isArrayEmpty.util.js';
import isObjectEmpty from 'utils/isObjectEmpty.util.js';

const DEFAULT_OFFICE = '83fdd64d-2c78-4b81-829d-76a81cdfd67d';

const Reserve = () => {
    const { value: locations } = useGetLocations();
    const { employeeLoggedInResult, employeeLoggenInLoading, employeeLoggedIn } = useEmployeesLoggedIn();
    const { value: myReservations } = useGetMyReservations();
    const dispatchToast = useToastDispatch();
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(true);
    const location = useLocation();
    const [office, setOffice] = useState(null);
    const [chosenDates, setChosenDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedSeat, setSelectedSeat] = useState({});
    const [selectedOffice, setSelectedOffice] = useState(DEFAULT_OFFICE);
    const [isModalShown, setisModalShown] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState({});
    const [showPopUp, setShowPopUp] = useState(false);
    const [employeeId, setEmployeeId] = useState('');
    const hasReservation = useMemo(
        () =>
            myReservations?.data?.some(({ date }) =>
                selectedDates?.some((e) => format(e, 'yyyy-MM-dd') === format(new Date(date), 'yyyy-MM-dd')),
            ),
        [myReservations?.data, selectedDates],
    );

    useEffect(() => {
        if (!location) {
            navigate(NAV.home, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        !isArrayEmpty(selectedDates) && !isObjectEmpty(selectedSeat)
            ? setIsDisabled(hasReservation)
            : setIsDisabled(true);
    }, [selectedDates, selectedSeat, myReservations, hasReservation]);

    useEffect(() => {
        employeeLoggedIn();
    }, [employeeLoggedIn]);

    useEffect(
        () =>
            void (!isArrayEmpty(selectedDates)
                ? setChosenDates(selectedDates.map((date) => format(date, 'yyyy-MM-dd')))
                : setChosenDates()),
        [selectedDates],
    );

    useEffect(
        () => void (employeeLoggedInResult && setEmployeeId(employeeLoggedInResult.id)),
        [employeeLoggedInResult],
    );

    useEffect(
        () => void (locations && setOffice(currentOffice(location, locations, selectedOffice))),
        [location, locations, selectedOffice],
    );

    useEffect(() => {
        hasReservation &&
            dispatchToast({
                type: 'error',
                message:
                    'You already have an active reservation for that period, please cancel it first if you want to change your desk.',
                duration: 2000,
            });
    }, [dispatchToast, hasReservation, selectedDates]);

    const handleCloseModal = useCallback(() => {
        setisModalShown(false);
    }, []);

    const handleConfirmClick = useCallback(() => {
        let convertedDates;
        if (format(selectedDates[0], 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')) {
            return;
        } else {
            convertedDates = selectedDates?.map((date) => {
                return format(date, 'yyyy-MM-dd');
            });
        }

        if (office.name && selectedSeat.code && selectedSeat.id) {
            const reservation = {
                id: selectedSeat?.id,
                desk: selectedSeat?.code,
                office: office.name,
                dates: convertedDates,
            };

            setSelectedReservation(reservation);
            setisModalShown(true);
        }
    }, [office?.name, selectedSeat?.code, selectedSeat?.id, selectedDates]);

    const handleCloseMapPopUp = useCallback(() => {
        setShowPopUp(false);
    }, []);

    const DeskPickerLoader =
        employeeLoggenInLoading && employeeLoggedInResult ? (
            <Loader />
        ) : (
            <DeskPicker
                chosenDate={chosenDates}
                locationId={location}
                office={office}
                officeId={selectedOffice}
                selectedSeat={selectedSeat}
                setSelectedSeat={setSelectedSeat}
                employeeId={employeeId}
            />
        );

    return (
        <section className={CN.contents}>
            <div className={`${CN.w70} ${CN.mobileW100} ${CN.flex} ${CN.flexWrap}`}>
                <div className={`${CN.w50} ${CN.column}`}>
                    <p className={CN.title}>Book the day(s) for your desk reservation</p>
                    <CustomDatePicker
                        selectedDates={selectedDates}
                        setSelectedDates={setSelectedDates}
                        setSelectedSeat={setSelectedSeat}
                    />
                </div>

                <div className={`${CN.w50} ${CN.column}`}>
                    <p className={CN.title}>
                        Book the room for your desk reservation (
                        <span className={CN.viewMapLink} onClick={() => setShowPopUp(true)}>
                            view map
                        </span>
                        )
                    </p>

                    <OfficePicker
                        offices={locations?.data[0]?.offices}
                        selectedOffice={selectedOffice}
                        setSelectedOffice={setSelectedOffice}
                        setOffice={setOffice}
                    />
                </div>

                <div
                    className={`${CN.w30} ${CN.mobileW100} ${CN.marginHorizontalAuto} ${CN.mt10} ${CN.tabletW50} ${CN.deskPicker}`}
                >
                    {selectedDates.length > 0 ? (
                        DeskPickerLoader
                    ) : (
                        <p className={`${CN.noDateChosenMessage} ${CN.marginHorizontalAuto}`}>
                            Please select the date(s)
                        </p>
                    )}
                </div>

                <div className={`${CN.w100} ${CN.flex} ${CN.mt10}`}>
                    <Button
                        className={CN.reserveBtn}
                        variation={isDisabled ? 'disabled' : 'filled'}
                        disabled={isDisabled}
                        type="button"
                        onClick={handleConfirmClick}
                    >
                        Reserve
                    </Button>
                </div>
            </div>

            <div className={`${CN.w30} ${CN.mobileW100} ${CN.tabletW80}`}>
                <p className={CN.title}>Reservations:</p>
                <ReserveTable location={location} selectedOffice={selectedOffice} selectedDates={selectedDates} />
            </div>

            {isModalShown && (
                <ConfirmReservationModal
                    closeModal={handleCloseModal}
                    isModalShown={isModalShown}
                    office={office}
                    reservation={selectedReservation}
                />
            )}
            {showPopUp && <MapPopUpReserve isModalShown={showPopUp} closeModal={handleCloseMapPopUp} />}
        </section>
    );
};

Reserve.propTypes = {};

export default Reserve;
