import { useEffect, useState } from 'react';
import PT from 'prop-types';

import generateAvatar from 'utils/generateAvatar.util.js';

const Avatar = ({ className, url, firstName, lastName, displayName }) => {
    const [imageUrl, setImageUrl] = useState();

    useEffect(() => {
        url && url ? setImageUrl(url) : setImageUrl(generateAvatar(displayName));
    }, [url, displayName]);

    return <img alt="my avatar" src={imageUrl} className={className} title={`${firstName + lastName}`} />;
};

Avatar.propTypes = {
    url: PT.string,
    firstName: PT.string,
    lastName: PT.string,
    displayName: PT.string,
    className: PT.string,
};

export default Avatar;
