import { useMemo } from 'react';
import { useAsyncFn } from 'react-use';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';
import unignore from 'utils/unignore.util';

const TYPE = 'json';
const URI = `${C.baseBeUrl}/reservations/office`;

const useGetOfficeReservations = () => {
    const { token } = useAccessToken(C.apiType.backend);

    const [{ error, value, loading }, getOfficeReservations] = useAsyncFn(
        async (locationId, officeId, dates) => {
            if (!token) {
                return;
            }

            const params = new URLSearchParams({
                locationId,
                officeId,
            });

            dates?.length > 0 &&
                dates.map((date) => {
                    return params.append('dates', date);
                });

            return await unignore('useGetOfficeReservations', fetchFromBe({ uri: URI, token, type: TYPE, params }));
        },
        [token],
    );

    return useMemo(
        () => ({
            getOfficeReservations,
            error,
            value,
            loading,
        }),
        [getOfficeReservations, error, value, loading],
    );
};

export default useGetOfficeReservations;
