const returnSelectedOffice = (offices, selectedOffice) => {
    return Number(
        offices &&
            selectedOffice &&
            Object.keys(offices).find((key) => {
                return offices[key].id === selectedOffice;
            }),
    );
};

export default returnSelectedOffice;
