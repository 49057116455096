import { createContext, useContext, useEffect, useReducer } from 'react';
import PT from 'prop-types';

import useGetAllReservations from 'hooks/api/Reservations/useGetAllReservations.hook.js';

import { useLocation } from './LocationContext.js';

const INITIAL_STATE = [];

const ReservationContext = createContext(null);
const ReservationDispatchContext = createContext(null);

const reservationReducer = (state, action) => {
    if (action.type === 'FETCH') {
        return action.reservations;
    } else if (action.type === 'UPDATE') {
        return [
            ...state.filter((reservation) => reservation.id !== action.reservation.reservationId),
            {
                ...state.filter((reservation) => reservation.id === action.reservation.reservationId)[0],
                confirmByAdmin: action.reservation.confirmByAdmin,
                adminNotes: action.reservation.adminNotes,
            },
        ];
    } else if (action.type === 'CANCEL') {
        return [...state.filter((reservation) => reservation.id !== action.reservation.reservationId)];
    } else {
        return state;
    }
};

const PAGE_NUMBER = 1;
const PAGE_SIZE = 100;

export const ReservationProvider = ({ children }) => {
    const location = useLocation();
    const { value: allReservations } = useGetAllReservations(location, PAGE_NUMBER, PAGE_SIZE);
    const [state, dispatch] = useReducer(reservationReducer, INITIAL_STATE);
    const hasReservations = allReservations && Object.keys(allReservations).length > 0;

    useEffect(() => {
        if (hasReservations) {
            const newReservations = !allReservations.errors
                ? allReservations.data
                      .map((item) => {
                          return { ...item, deskCode: item.desk.code };
                      })
                      .filter((item) => item.status === 'ACTIVE' || item.status === 'CONFIRMED')
                : [];
            dispatch({ type: 'FETCH', reservations: newReservations });
        }
    }, [allReservations, hasReservations]);

    return (
        <ReservationContext.Provider value={state}>
            <ReservationDispatchContext.Provider value={dispatch}>{children}</ReservationDispatchContext.Provider>
        </ReservationContext.Provider>
    );
};

export const useReservation = () => {
    return useContext(ReservationContext);
};

export const useReservationDispatch = () => {
    return useContext(ReservationDispatchContext);
};

ReservationProvider.propTypes = {
    children: PT.node.isRequired,
};
