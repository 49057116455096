import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useLocation } from 'context/LocationContext.js';
import { useToastDispatch } from 'context/ToastContext.js';
import { format, parseISO } from 'date-fns';
import PT from 'prop-types';

import Button from 'components/common/Button/Button.component.jsx';
import Modal from 'components/common/Modal/Modal.component.jsx';
import ReservationModalContent from 'components/common/ReservationModalContent/ReservationModalContent.component.jsx';
import usePostReservations from 'hooks/api/Reservations/usePostReservations.hook.js';
import logger from 'utils/logger.util.js';
import unignore from 'utils/unignore.util.js';

import CN from './ConfirmReservationModal.module.scss';

const L = logger('ConfirmReservationModal');

const ConfirmReservationModal = ({ closeModal, isModalShown = false, office, reservation }) => {
    const type = true;
    const [reservationNote, setReservationNote] = useState('');
    const [selectedDates, setSelectedDates] = useState('');
    const [noteInputErrorVisible, setNoteInputErrorVisible] = useState(false);
    const { reserveError, reserve } = usePostReservations();
    const dispatchToast = useToastDispatch();
    const location = useLocation();
    const reservationNoteRef = useRef();
    const navigate = useNavigate();
    const [sortedReservations, setSortedReservations] = useState();
    const CONTAINER_CLASSES = classNames('d-flex', CN.container);
    //split logic
    const onConfirmModalSuccess = useCallback(
        (response) => {
            if (response.succeeded) {
                dispatchToast({
                    type: 'success',
                    message: 'Your reservation is confirmed.',
                    duration: 2000,
                });
                navigate('/reservations', { state: { tabIndex: 1 }, replace: true });
            }
            if (!reserveError && response?.message && !response?.succeeded) {
                if (response?.message === 'Reservation already exists for the selected days!') {
                    dispatchToast({
                        type: 'error',
                        message:
                            'You already have an active reservation for that period, please cancel it first if you want to change your desk.',
                        duration: 4000,
                    });
                } else if (!response?.succeeded && response?.message) {
                    L.warn(response?.errors);
                    dispatchToast({
                        type: 'error',
                        message: 'Your reservation didn\'t go through',
                        duration: 4000,
                    });
                }
            } else if (!response?.message && response?.errors) {
                dispatchToast({
                    type: 'error',
                    message: 'Your reservation didn\'t go through',
                    duration: 2000,
                });
            } else L.warn('this should not happen');
        },
        [dispatchToast, navigate, reserveError],
    );
    const changeInputInReservationNote = useCallback(() => {
        if (reservationNoteRef?.current?.value.trim().split(/ +/).join(' ').length <= 100) {
            setReservationNote(reservationNoteRef?.current?.value.trim().split(/ +/).join(' '));
        } else {
            dispatchToast({
                type: 'error',
                message: 'Maximum 100 characters allowed',
                duration: 4000,
            });
        }
    }, [setReservationNote, reservationNoteRef, dispatchToast]);

    const handleXbuttonClick = useCallback(() => {
        setReservationNote('');
        closeModal();
    }, [closeModal]);

    const handleReservationConfirmation = useCallback(
        (locationId, officeId, deskId, dates, notes) => {
            const body = JSON.stringify({ locationId, officeId, deskId, dates, notes });
            const promise = unignore('ConfirmReservationModal.reserve()', reserve(body));

            promise.then((response) => onConfirmModalSuccess(response));
            promise.then(() => {
                setReservationNote('');
            });
            closeModal();
        },
        [reserve, closeModal, onConfirmModalSuccess],
    );

    useEffect(() => {
        reservation.dates &&
            setSortedReservations({
                ...reservation,
                dates: reservation?.dates?.map((date) => format(parseISO(date), ' dd/MM/yyyy')).join(),
            });
    }, [reservation]);

    useEffect(() => {
        setSelectedDates(reservation.dates);
        noteInputErrorVisible && reservationNote.length <= 100
            ? setNoteInputErrorVisible(false)
            : reservationNote.length > 100 && setNoteInputErrorVisible(true);
    }, [reservationNote, noteInputErrorVisible, reservation.dates]);

    return (
        <Modal closeModal={handleXbuttonClick} isModalShown={isModalShown} confirm>
            <div className={CONTAINER_CLASSES}>
                <ReservationModalContent reservation={sortedReservations} type={type} />
                <p className={CN.note}>
                    <strong>Note:</strong> <span>(100 characters max)</span>
                </p>
                <textarea
                    className={CN.noteInput}
                    defaultValue={reservationNote}
                    onChange={changeInputInReservationNote}
                    ref={reservationNoteRef}
                    maxLength="100"
                    placeholder={'Note: Do you need any equipment for your desk? (e.g. monitor, keyboard, mouse...)'}
                ></textarea>
                <p className={classNames(CN.noteInputLengthError, noteInputErrorVisible && CN.showNoteInputError)}>
                    100 characters max, you have entered {reservationNote.length}.
                </p>
                <Button
                    disabled={reservationNote.length > 100}
                    variation="filled"
                    className={CN.confirmBtn}
                    onClick={() =>
                        handleReservationConfirmation(
                            location,
                            office.id,
                            reservation.id,
                            selectedDates,
                            reservationNote,
                        )
                    }
                >
                    Reserve
                </Button>
            </div>
        </Modal>
    );
};

ConfirmReservationModal.propTypes = {
    closeModal: PT.func,
    isModalShown: PT.bool,
    office: PT.object,
    reservation: PT.object,
};

export default ConfirmReservationModal;
