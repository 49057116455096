import { useIsAuthenticated } from '@azure/msal-react';
import PT from 'prop-types';

const AuthenticatedContent = ({ children }) => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <>{children}</>;
    }

    return null;
};

AuthenticatedContent.propTypes = {
    children: PT.node,
};

export default AuthenticatedContent;
