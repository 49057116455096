import PT from 'prop-types';

import CN from './ReservationModalContent.module.scss';

const ReservationModalContent = ({ reservation: { desk, office, dates }, type }) => {
    return (
        <>
            <h3 className={`${CN.textColor} ${CN.fw400}`}>
                <strong>{type ? 'Confirm' : 'Cancel'} reservation</strong>
            </h3>

            {type && (
                <p className={`${CN.textColor} ${CN.fw400} ${CN.mb1} ${CN.pt25}`}> Confirm your reservation for:</p>
            )}

            {!type && (
                <p className={`${CN.textColor} ${CN.fw400} ${CN.mb1} ${CN.pt25}`}>
                    Are you sure you want to cancel your reservation for:
                </p>
            )}
            <p className={`${CN.textColor} ${CN.fw400} ${CN.mb1}`}>
                Desk: <strong>{desk}</strong>
            </p>
            <p className={`${CN.textColor} ${CN.fw400} ${CN.mb1}`}>
                Room: <strong>{office}</strong>
            </p>
            <p className={`${CN.textColor} ${CN.fw400} ${CN.mb1}`}>
                Day(s): <strong>{dates}</strong>
            </p>
        </>
    );
};

ReservationModalContent.defaultProps = {
    desk: '[desk number]',
    office: '[office number]',
    dates: '[date]',
    reservation: {},
};

ReservationModalContent.propTypes = {
    dates: PT.string || '[date]',
    desk: PT.string || '[desk number]',
    office: PT.string || '[office number]',
    reservation: PT.object,
    type: PT.bool,
};
export default ReservationModalContent;
