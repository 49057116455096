import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useLocation, useLocationDispatch } from 'context/LocationContext';
import PT from 'prop-types';

import CN from './Home.module.scss';

const LocationSVG = ({ name, id, index }) => {
    const dispatch = useLocationDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const FOREIGN_OBJECT_CLASSES = classNames('relative', name !== 'Skopje' && CN.notAllowed, CN.textCentered);
    const TEXT_CLASSES = classNames(CN.textSVG, 'd-flex', 'flex-center');

    const handleSvgClick = () => {
        if (name === 'Skopje') {
            dispatch({ location: id });
            navigate('/reserve', { replace: true });
        }
    };

    return (
        <svg
            onClick={handleSvgClick}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={CN['location' + index]}
        >
            {location === id ? (
                <circle cx="50%" cy="50%" r="50%" fill="#89D15F" />
            ) : (
                <circle cx="50%" cy="50%" r="50%" fill="#299DE5" />
            )}

            <foreignObject x="0" y="0" width="100" height="100" className={FOREIGN_OBJECT_CLASSES}>
                <div className={TEXT_CLASSES}>{name.toUpperCase()}</div>
            </foreignObject>
        </svg>
    );
};

LocationSVG.propTypes = { name: PT.string, id: PT.string, index: PT.number };

export default LocationSVG;
