const HOLIDAYS = [
    { date: '2022-01-01', name: 'New Year' },
    { date: '2022-01-06', name: 'Christmas Eve' },
    { date: '2022-01-07', name: 'Christmas Day (Orthodox)' },
    { date: '2022-05-01', name: 'Labor Day' },
    { date: '2022-04-22', name: 'Good Friday' },
    { date: '2022-04-25', name: 'Easter (Orthodox)' },
    { date: '2022-05-02', name: 'Ramadan' },
    { date: '2022-05-24', name: 'St. Cyril and Methodius' },
    { date: '2022-06-10', name: 'Pentecost' },
    { date: '2022-08-02', name: 'Day of the Republic' },
    { date: '2022-08-28', name: 'Dormition of the Mother of God' },
    { date: '2022-09-08', name: 'Independence day' },
    { date: '2022-10-11', name: 'Day of Macedonian Uprising' },
    { date: '2022-10-23', name: 'Day of the Macedonian Revolutionary Struggle' },
    { date: '2022-12-08', name: 'Saint Clement of Ohrid' },
];

export default HOLIDAYS;
