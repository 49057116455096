import { useMemo } from 'react';
import { useAsyncFn } from 'react-use';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';
import unignore from 'utils/unignore.util';

const useGetReports = () => {
    const type = 'json';
    const method = 'POST';

    const { token } = useAccessToken(C.apiType.backend);

    const [data, getReports] = useAsyncFn(
        async (body) => {
            if (!token) {
                return;
            }
            const uri = `${C.baseBeUrl}/reports`;
            return unignore('useGetReports', fetchFromBe({ uri, token, type, method, body }));
        },
        [token],
    );

    return useMemo(
        () => ({
            getReports,
            reportsData: data.value,
            reportsLoading: data.loading,
            reportsError: data.error,
        }),
        [data, getReports],
    );
};

export default useGetReports;
