import { useAsync } from 'react-use';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';

const useUserData = () => {
    const { token } = useAccessToken();

    return useAsync(async () => {
        if (!token) {
            return;
        }
        return await fetchFromBe({ uri: C.graphMeV1, token });
    }, [token]);
};

export default useUserData;
