import { isString } from 'lodash/fp';

import unignore from './unignore.util';

const createObjectURL = (window.URL || window.webkitURL).createObjectURL;

const fetchFromBe = async ({ uri, token, type, params, method = 'GET', body }) => {
    const headers = {};

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const stringifiedBody = body && !isString(body) ? JSON.stringify(body) : body;

    if (stringifiedBody) {
        headers['Content-Type'] = 'text/json';
    }

    const stringifiedParams = params ? params.toString() : '';

    const promise = fetch(`${uri}?${stringifiedParams}`, { method, headers, body: stringifiedBody });

    unignore('fetchFromBe()', promise);

    const res = await promise;

    if ('blob' === type) {
        if (res.status === 200) {
            const blobBody = await res.blob();
            return createObjectURL(blobBody);
        }
        if (res.status === 404) {
            return await res.json();
        }
    }

    if ('text' === type) {
        const textBody = await res.text();
        return textBody;
    }

    const jsonBody = await res.json();

    return jsonBody;
};

export default fetchFromBe;
