import { useCallback } from 'react';
import { REPORTS_ACTIONS, useReports, useReportsDispatch } from 'context/ReportsContext';

import Autocomplete from 'components/common/Autocomplete/Autocomplete.component';

const OfficesAutocomplete = () => {
    const {
        state: { officesList, selectedOffice },
    } = useReports();
    const { dispatch } = useReportsDispatch();

    const handleSelectLocation = useCallback(
        ({ value, label }) => {
            dispatch({ type: REPORTS_ACTIONS.selectOffice, payload: { id: value, name: label } });
            dispatch({ type: REPORTS_ACTIONS.getDesks, payload: value.id });
            dispatch({ type: REPORTS_ACTIONS.selectDesk, payload: {} });
        },
        [dispatch],
    );

    const value = officesList?.map(({ id, name }) => ({ value: id, label: name }));

    return (
        <Autocomplete
            options={value}
            loading={false}
            placeholder="Search room:"
            onSelect={handleSelectLocation}
            emptyMessage="Please select a location first."
            hasValue={!!selectedOffice.id}
        />
    );
};

export default OfficesAutocomplete;
