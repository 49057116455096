import { useCallback, useState } from 'react';
import PT from 'prop-types';

import CN from './ReservationsTableCheckbox.module.scss';

const ReservationsTableCheckbox = ({ confirmByAdmin, id, adminNotes, deskCode, onUpdate }) => {
    const [checked, setCheked] = useState(confirmByAdmin);

    const handleChange = useCallback(
        (e) => {
            setCheked(e.target.checked);
            onUpdate(id, adminNotes, e.target.checked, deskCode);
        },
        [onUpdate, id, adminNotes, deskCode],
    );

    return <input type="checkbox" onChange={handleChange} checked={checked} className={CN.checkbox} />;
};

ReservationsTableCheckbox.propTypes = {
    confirmByAdmin: PT.bool,
    deskCode: PT.string,
    onUpdate: PT.func,
    id: PT.string,
    adminNotes: PT.string,
};

export default ReservationsTableCheckbox;
