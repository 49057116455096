const InfoIconScan = () => (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.5 0C4.2617 0 0 4.2617 0 9.5C0 14.7383 4.2617 19 9.5 19C14.7383 19 19 14.7383 19 9.5C19 4.2617 14.7383 0 9.5 0ZM9.5 3.895C9.74426 3.895 9.98303 3.96743 10.1861 4.10313C10.3892 4.23884 10.5475 4.43172 10.641 4.65739C10.7345 4.88305 10.7589 5.13137 10.7113 5.37094C10.6636 5.6105 10.546 5.83056 10.3733 6.00328C10.2006 6.17599 9.9805 6.29362 9.74094 6.34127C9.50137 6.38892 9.25305 6.36447 9.02739 6.27099C8.80172 6.17752 8.60884 6.01922 8.47313 5.81613C8.33743 5.61303 8.265 5.37426 8.265 5.13C8.265 4.80246 8.39511 4.48833 8.62672 4.25672C8.85833 4.02512 9.17246 3.895 9.5 3.895ZM11.78 14.63H7.6C7.39844 14.63 7.20513 14.5499 7.0626 14.4074C6.92007 14.2649 6.84 14.0716 6.84 13.87C6.84 13.6684 6.92007 13.4751 7.0626 13.3326C7.20513 13.1901 7.39844 13.11 7.6 13.11H8.93V8.93H8.17C7.96844 8.93 7.77513 8.84993 7.6326 8.7074C7.49007 8.56487 7.41 8.37156 7.41 8.17C7.41 7.96844 7.49007 7.77513 7.6326 7.6326C7.77513 7.49007 7.96844 7.41 8.17 7.41H9.69C9.89156 7.41 10.0849 7.49007 10.2274 7.6326C10.3699 7.77513 10.45 7.96844 10.45 8.17V13.11H11.78C11.9816 13.11 12.1749 13.1901 12.3174 13.3326C12.4599 13.4751 12.54 13.6684 12.54 13.87C12.54 14.0716 12.4599 14.2649 12.3174 14.4074C12.1749 14.5499 11.9816 14.63 11.78 14.63Z"
            fill="#4D4D4D"
        />
    </svg>
);

export default InfoIconScan;
