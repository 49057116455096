import { useReportsDispatch } from 'context/ReportsContext';

import Button from 'components/common/Button/Button.component';

import CN from '../Reports.module.scss';

const ExportButton = () => {
    const { getTableData } = useReportsDispatch();
    return (
        <Button className={CN.exportBtn} onClick={getTableData}>
            Export report
        </Button>
    );
};

export default ExportButton;
