import { useAsync } from 'react-use';
import { isFunction } from 'lodash/fp';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';

const useGetAllReservations = (locationId, pageNumber, pageSize, onSuccess) => {
    const type = 'json';
    const { token } = useAccessToken(C.apiType.backend);
    const params = new URLSearchParams({
        locationId,
        pageNumber,
        pageSize,
    });

    return useAsync(async () => {
        if (!token || !locationId) {
            return;
        }
        const uri = `${C.baseBeUrl}/reservations/all`;
        const promise = fetchFromBe({ uri, token, type, params });

        if (isFunction(onSuccess)) {
            promise.then(onSuccess);
        }
        return await promise;
    }, [token]);
};

export default useGetAllReservations;
