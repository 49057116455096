import { LogLevel } from '@azure/msal-browser';

import logger from './logger.util.js';

const { debug, error, info, warn } = logger('MS log callback');

const MAP = Object.freeze({
    [LogLevel.Error]: error,
    [LogLevel.Warning]: warn,
    [LogLevel.Info]: info,
    [LogLevel.Verbose]: debug,
});

const mslog = (level, message, containsPii) => void (!containsPii && MAP[level](message));

export default mslog;
