import { useCallback } from 'react';
import { REPORTS_ACTIONS, useReports, useReportsDispatch } from 'context/ReportsContext';

import Autocomplete from 'components/common/Autocomplete/Autocomplete.component';

const StatusAutocomplete = () => {
    const {
        state: { statusList },
    } = useReports();
    const { dispatch } = useReportsDispatch();

    const handleSelectStatus = useCallback(
        (value) => {
            dispatch({ type: REPORTS_ACTIONS.selectStatus, payload: value });
        },
        [dispatch],
    );

    return <Autocomplete options={statusList} onSelect={handleSelectStatus} placeholder="Status:" loading={false} />;
};

export default StatusAutocomplete;
