import { useAsync } from 'react-use';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';

const useGetMyReservations = () => {
    const type = 'json';
    const { token } = useAccessToken(C.apiType.backend);
    const params = new URLSearchParams({});

    return useAsync(async () => {
        if (!token) {
            return;
        }
        const uri = `${C.baseBeUrl}/my-reservations`;
        return await fetchFromBe({ uri, token, type, params });
    }, [token]);
};

export default useGetMyReservations;
