import { useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { useMsal } from '@azure/msal-react';

import conf from 'core/global.conf.js';
import logger from 'utils/logger.util.js';
import unignore from 'utils/unignore.util.js';

const L = logger('useLogOut');

const DEFAULT_LOGIN_TYPE = 'redirect';

const { baseFeUrl } = conf;

const useLogOut = (type = DEFAULT_LOGIN_TYPE) => {
    const { instance } = useMsal();

    const [{ error, value }, logout] = useAsyncFn(async () => {
        if (DEFAULT_LOGIN_TYPE !== type) {
            const message = `Unsupported logout type: ${type}`;
            L.error(message);
            throw new Error(message);
        }

        sessionStorage.removeItem('location');

        return unignore(
            'useLogOut',
            instance.logoutRedirect({ account: instance.getActiveAccount(), postLogoutRedirectUri: baseFeUrl }),
        );
    }, [instance, type]);

    return useMemo(
        () => ({
            logout,
            logoutError: error,
            logoutResult: value,
        }),
        [logout, error, value],
    );
};

export default useLogOut;
