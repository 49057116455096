import { useMemo } from 'react';
import { useAsyncFn } from 'react-use';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';
import unignore from 'utils/unignore.util.js';

const TYPE = 'json';
const METHOD = 'POST';
const URI = `${C.baseBeUrl}/reservations`;

const usePostReservations = () => {
    const { token } = useAccessToken(C.apiType.backend);

    const [data, reserve] = useAsyncFn(
        async (body) => {
            if (!token) {
                return;
            }
            return unignore('usePostReservations', fetchFromBe({ uri: URI, token, type: TYPE, method: METHOD, body }));
        },
        [token],
    );

    return useMemo(
        () => ({
            reserve,
            reserveError: data.error,
            reserveResult: data.value,
        }),
        [reserve, data],
    );
};

export default usePostReservations;
