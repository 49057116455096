import { useCallback } from 'react';
import classNames from 'classnames';
import PT from 'prop-types';

import Button from '../Button/Button.component';
import Modal from '../Modal/Modal.component';
import ReservationModalContent from '../ReservationModalContent/ReservationModalContent.component';

import CN from './CancelReservationModal.module.scss';

const CancelReservationModal = ({ reservation, isModalShown = false, closeModal, onCancel }) => {
    const CONTAINER_CLASSES = classNames('d-flex', CN.container);
    const BTN_CONTAINER_CLASSES = classNames('d-flex', CN.btnContainer);
    const handleCancelReservation = useCallback(() => {
        onCancel(reservation.id);
    }, [onCancel, reservation]);

    return (
        <Modal isModalShown={isModalShown} closeModal={closeModal}>
            <div className={CONTAINER_CLASSES}>
                <ReservationModalContent reservation={reservation} />
                <div className={BTN_CONTAINER_CLASSES}>
                    <Button variation="empty" onClick={closeModal}>
                        No
                    </Button>
                    <Button variation="empty" onClick={handleCancelReservation}>
                        Yes
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

CancelReservationModal.propTypes = {
    reservation: PT.object,
    isModalShown: PT.bool,
    closeModal: PT.func,
    onCancel: PT.func,
};

export default CancelReservationModal;
