import PT from 'prop-types';

import CN from './XButton.module.scss';

const XButton = ({ onClick }) => {
    return (
        <button onClick={onClick} className={CN.button}>
            <span></span>
            <span></span>
        </button>
    );
};

XButton.propTypes = {
    onClick: PT.func,
};

export default XButton;
