import { useMemo, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import classNames from 'classnames';
import HOLIDAYS from 'consts/holidays/holidays.const';
import { addDays, addYears, eachWeekendOfYear, format } from 'date-fns';
import PT from 'prop-types';

import CN from 'components/common/DatePicker/DatePicker.module.scss';
import useComponentVisible from 'hooks/useComponentVisible.hook.js';

import 'react-day-picker/dist/style.css';

const DEFAULT_MONTH = new Date();
const MAX_SELECTABLE_DAYS = 5;
const DISABLED_DAYS = [
    {
        before: addDays(DEFAULT_MONTH, 1),
    },
    {
        after: addDays(DEFAULT_MONTH, 7),
    },
];
const HIDDEN_WEEKENDS = [...eachWeekendOfYear(DEFAULT_MONTH), ...eachWeekendOfYear(addYears(DEFAULT_MONTH, 1))];
const formatWeekDays = (date) => format(date, 'iii');

const CustomDatePicker = ({ selectedDates, setSelectedDates, setSelectedSeat }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [days, setDays] = useState([]);
    const holidays = HOLIDAYS.map(({ date }) => new Date(date));

    const handleDatePickerButton = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    const handleDaySelect = (date) => {
        if (date) {
            setDays(
                date
                    .sort((a, b) => {
                        let dateA = new Date(a);
                        let dateB = new Date(b);
                        return dateA - dateB;
                    })
                    .map((date) => date),
            );
            setSelectedDates(
                date
                    .sort((a, b) => {
                        let dateA = new Date(a);
                        let dateB = new Date(b);
                        return dateA - dateB;
                    })
                    .map((date) => date),
            );
            setSelectedSeat({});
        }
    };
    const returnRange = (days) => {
        let f = days[0];
        let l = days[days.length - 1];
        return `${format(f, 'dd/MM/yyyy')} - ${format(l, 'dd/MM/yyyy')}`;
    };

    const displayDates = useMemo(() => {
        const displayDates =
            days?.length > 3 ? returnRange(days) : days?.map((date) => format(date, ' dd/MM/yyyy')).join();
        return displayDates;
    }, [days]);

    return (
        <div className="d-flex">
            <div ref={ref} className={CN.container}>
                <button
                    type="button"
                    aria-haspopup="grid"
                    aria-expanded={isComponentVisible}
                    className={classNames(CN.dateButton, isComponentVisible ? CN.expanded : '')}
                    onClick={handleDatePickerButton}
                >
                    Date(s): {displayDates}
                </button>
                {isComponentVisible && (
                    <DayPicker
                        mode="multiple"
                        fromMonth={DEFAULT_MONTH}
                        max={MAX_SELECTABLE_DAYS}
                        disabled={DISABLED_DAYS}
                        hidden={HIDDEN_WEEKENDS}
                        selected={days}
                        onSelect={handleDaySelect}
                        numberOfMonths={2}
                        formatters={{
                            formatWeekdayName: formatWeekDays,
                        }}
                        modifiers={{ holidays }}
                        modifiersClassNames={{ holidays: CN.holiday }}
                    />
                )}
            </div>
        </div>
    );
};

CustomDatePicker.propTypes = {
    selectedDates: PT.array,
    setSelectedDates: PT.func,
    setSelectedSeat: PT.func,
};

export default CustomDatePicker;
