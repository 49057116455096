import { Route, Routes } from 'react-router-dom';
import { MyReservationProvider } from 'context/MyReservationContext.js';
import { ReportsProvider } from 'context/ReportsContext';
import { ReservationProvider } from 'context/ReservationContext.js';
import PT from 'prop-types';

import AuthenticatedContent from 'components/layout/Auth/AuthenticatedContent.component.jsx';
import UnauthenticatedContent from 'components/layout/Auth/UnauthenticatedContent.component.jsx';
import Home from 'components/layout/Home/Home.component.jsx';
import Reports from 'components/layout/Reports/Reports.component.jsx';
import Reservations from 'components/layout/Reservations/Reservations.component.jsx';
import ReservationsAdmin from 'components/layout/Reservations/ReservationsAdmin.component.jsx';
import Reserve from 'components/layout/Reserve/Reserve.component.jsx';
import Scan from 'components/layout/Scan/Scan.component.jsx';
import NAV from 'core/nav.conf.js';
import useIsAdmin from 'hooks/useIsAdmin.hook.js';

import CN from './Main.module.scss';

const Main = () => {
    const { admin } = useIsAdmin();

    return (
        <main data-testid="main" className={CN.container}>
            <AuthenticatedContent>
                <Routes>
                    <Route path={NAV.home} element={<Home />} />
                    <Route path={NAV.reserve} element={<Reserve />} />
                    {admin ? (
                        <>
                            <Route
                                path={NAV.reservations}
                                element={
                                    <ReservationProvider>
                                        <MyReservationProvider>
                                            <ReservationsAdmin />
                                        </MyReservationProvider>
                                    </ReservationProvider>
                                }
                            />{' '}
                        </>
                    ) : (
                        <>
                            <Route
                                path={NAV.reservations}
                                element={
                                    <MyReservationProvider>
                                        <Reservations />
                                    </MyReservationProvider>
                                }
                            />{' '}
                        </>
                    )}
                    <Route path={NAV.scan} element={<Scan />} />
                    <Route
                        path={NAV.reports}
                        element={
                            <ReportsProvider>
                                <Reports />
                            </ReportsProvider>
                        }
                    />
                </Routes>
            </AuthenticatedContent>
            <UnauthenticatedContent>
                <p>Hello not logged in user</p>
            </UnauthenticatedContent>
        </main>
    );
};

Main.propTypes = {
    children: PT.node,
};

export default Main;
