import { useCallback, useEffect, useMemo, useState } from 'react';
import PT from 'prop-types';

import CN from 'components/common/OfficePicker/OfficePicker.module.scss';
import useComponentVisible from 'hooks/useComponentVisible.hook.js';
import generateOptionsList from 'utils/generateOptionsList.util.js';
import returnSelectedOffice from 'utils/returnSelectedOffice.util.js';

const OfficePicker = ({ offices, selectedOffice, setSelectedOffice, setOffice }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const [selectedOption, setSelectedOption] = useState();

    useEffect(
        () => selectedOffice && offices && setSelectedOption(returnSelectedOffice(offices, selectedOffice)),
        [offices, selectedOffice],
    );

    const optionsList = useMemo(() => generateOptionsList(offices), [offices]);

    const handleSelectedChoice = useCallback(
        (index) => {
            setSelectedOption(index);
            setIsComponentVisible(false);
            setSelectedOffice(offices[index].id);
            setOffice(offices[index]);
        },
        [offices, setIsComponentVisible, setOffice, setSelectedOffice],
    );

    const handleToggleOptions = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    const handleKeyDown = useCallback(
        (e, index) => {
            switch (e.key) {
                case ' ':
                case 'SpaceBar':
                case 'Enter':
                    e.preventDefault();
                    handleSelectedChoice(index);
                    break;
                default:
                    break;
            }
        },
        [handleSelectedChoice],
    );

    const handleListKeyDown = useCallback(
        (e) => {
            switch (e.key) {
                case 'Escape':
                    e.preventDefault();
                    setIsComponentVisible(false);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setSelectedOption(selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1);
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    setSelectedOption(selectedOption === optionsList.length - 1 ? 0 : selectedOption + 1);
                    break;
                default:
                    break;
            }
        },
        [optionsList?.length, selectedOption, setIsComponentVisible],
    );

    const officeList = useMemo(() => {
        return optionsList?.map((option, index) => {
            return (
                <li
                    id={index}
                    role="option"
                    key={index}
                    aria-selected={selectedOption === index}
                    tabIndex={0}
                    onKeyDown={() => handleKeyDown(index)}
                    onClick={() => handleSelectedChoice(index)}
                >
                    {offices && offices[option].name}
                </li>
            );
        });
    }, [handleKeyDown, handleSelectedChoice, offices, optionsList, selectedOption]);

    return (
        <div className={'d-flex'}>
            <div ref={ref} className={CN.container}>
                <button
                    type="button"
                    aria-haspopup="listbox"
                    aria-expanded={isComponentVisible}
                    className={isComponentVisible ? CN.expanded : ''}
                    onClick={handleToggleOptions}
                    onKeyDown={handleListKeyDown}
                >
                    Room: {offices && Object.keys(offices).length > 0 && offices[selectedOption]?.name}
                </button>
                {isComponentVisible && (
                    <ul
                        className={`${CN.options} ${isComponentVisible ? CN.show : ''}`}
                        role="listbox"
                        aria-activedescendant={offices[selectedOption]}
                        tabIndex={-1}
                        onKeyDown={handleListKeyDown}
                    >
                        {officeList}
                    </ul>
                )}
            </div>
        </div>
    );
};

OfficePicker.propTypes = {
    offices: PT.array,
    selectedOffice: PT.string || null,
    setSelectedOffice: PT.func,
    setOffice: PT.func,
};

export default OfficePicker;
