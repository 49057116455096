import { useCallback } from 'react';
import { REPORTS_ACTIONS, useReports, useReportsDispatch } from 'context/ReportsContext';

import Autocomplete from 'components/common/Autocomplete/Autocomplete.component';

const DesksAutocomplete = () => {
    const {
        state: { desksList, selectedDesk },
    } = useReports();
    const { dispatch } = useReportsDispatch();

    const desks = desksList?.map(({ id, code }) => ({ value: id, label: code }));
    const desksWithAllDesksOption = (desks?.length > 0 && [{ value: 'all-desks', label: 'All desks' }, ...desks]) || [];

    const handleSelectLocation = useCallback(
        ({ value, label }) => {
            dispatch({
                type: REPORTS_ACTIONS.selectDesk,
                payload: value === '' || value === 'all-desks' ? {} : { id: value, code: label },
            });
        },
        [dispatch],
    );

    return (
        <Autocomplete
            options={desksWithAllDesksOption}
            loading={false}
            placeholder="Search Desks:"
            onSelect={handleSelectLocation}
            emptyMessage="Please select a room first."
            hasValue={!!selectedDesk.id}
        />
    );
};

export default DesksAutocomplete;
