import { NavLink } from 'react-router-dom';
import { useLocation } from 'context/LocationContext';

import logoutIcon from 'assets/icons/logout.svg';
import LOGO from 'assets/logo.png';
import Avatar from 'components/common/Avatar/Avatar.component.jsx';
import Button from 'components/common/Button/Button.component.jsx';
import HomeIcon from 'components/common/Icons/HomeIcon/HomeIcon.component.jsx';
import ReportIcon from 'components/common/Icons/ReportIcon/ReportIcon.component.jsx';
import ReservationIcon from 'components/common/Icons/ReservationIcon/ReservationIcon.component.jsx';
import ReserveIcon from 'components/common/Icons/ReserveIcon/ReserveIcon.component.jsx';
import ScanIcon from 'components/common/Icons/ScanIcon/ScanIcon.component.jsx';
import AuthenticatedContent from 'components/layout/Auth/AuthenticatedContent.component.jsx';
import UnauthenticatedContent from 'components/layout/Auth/UnauthenticatedContent.component.jsx';
import NAV from 'core/nav.conf.js';
import useAvatar from 'hooks/useAvatar.hook.js';
import useIsAdmin from 'hooks/useIsAdmin.hook.js';
import useLogIn from 'hooks/useLogIn.hook.js';
import useLogOut from 'hooks/useLogOut.hook.js';
import useUserData from 'hooks/useUserData.hook.js';
import logger from 'utils/logger.util.js';

import CN from './Navbar.module.scss';

const L = logger('Navbar');

export const Navbar = () => {
    const { login, loginError } = useLogIn();
    const { logout, logoutError } = useLogOut();
    const { url } = useAvatar();
    const { value, error } = useUserData();
    const location = useLocation();
    const { admin } = useIsAdmin();

    if (error) {
        L.warn(error);
    }

    if (loginError || logoutError) {
        L.warn({ loginError, logoutError });
    }

    const handleNavClick = (e, currentLocation) => {
        if ((currentLocation === NAV.reserve || currentLocation === NAV.reports) && !location) e.preventDefault();
    };

    return (
        <nav data-bem="Navbar" className={CN.container}>
            <div data-bem="Navbar__left">
                <img src={LOGO} className={CN.logo} alt="HotDesks Logo" />
                <ul className={CN.navLinksList}>
                    <li>
                        <NavLink className={({ isActive }) => (isActive ? CN.active : '')} to={NAV.home}>
                            <HomeIcon />
                            <span>Home</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={(e) => handleNavClick(e, NAV.reserve)}
                            className={({ isActive }) => (isActive ? CN.active : '')}
                            to={NAV.reserve}
                        >
                            <ReserveIcon />
                            <span className={!location ? CN.disabled : ''}>Reserve</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={(e) => handleNavClick(e, NAV.reservations)}
                            className={({ isActive }) => (isActive ? CN.active : '')}
                            to={NAV.reservations}
                        >
                            <ReservationIcon />
                            <span>Reservations</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={(e) => handleNavClick(e, NAV.scan)}
                            className={({ isActive }) => (isActive ? CN.active : '')}
                            to={NAV.scan}
                        >
                            <ScanIcon />
                            <span>Scan</span>
                        </NavLink>
                    </li>
                    {admin && (
                        <li>
                            <NavLink
                                onClick={(e) => handleNavClick(e, NAV.reports)}
                                className={({ isActive }) => (isActive ? CN.active : '')}
                                to={NAV.reports}
                            >
                                <ReportIcon />
                                <span className={!location ? CN.disabled : ''}>Reports</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
            <div data-bem="Navbar__right" className={CN.navBarRight}>
                {url && value && (
                    <Avatar
                        className={CN.avatar}
                        url={url && url.error ? '' : url}
                        firstName={value?.givenName}
                        lastName={value?.surname}
                        displayName={value?.displayName}
                    />
                )}
                <AuthenticatedContent>
                    <Button link className={CN.logInOut} onClick={logout}>
                        <img src={logoutIcon} alt="logout icon" />
                        Log Out
                    </Button>
                </AuthenticatedContent>
                <UnauthenticatedContent>
                    <Button link className={CN.logInOut} onClick={login}>
                        Log In
                    </Button>
                </UnauthenticatedContent>
            </div>
        </nav>
    );
};

Navbar.propTypes = {};

export default Navbar;
