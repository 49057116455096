import PT from 'prop-types';

import SkopjeOfficeMap from '../Maps/SkopjeOfficeMap';
import Modal from '../Modal/Modal.component';

import CN from './MapPopUpReserve.module.scss';

const officeMaps = {
    skopje: <SkopjeOfficeMap className={CN.map} />,
};

const MapPopUpReserve = ({ isModalShown = false, closeModal, office }) => {
    return (
        <Modal isModalShown={isModalShown} closeModal={closeModal}>
            <div className={CN.popUpModal}>
                <div className={CN.popUpModalImg}>{office && officeMaps[office]}</div>
            </div>
        </Modal>
    );
};

MapPopUpReserve.defaultProps = {
    isModalShown: false,
    office: 'skopje', //sofia||velikoTarnovo||skopje
};

MapPopUpReserve.propTypes = {
    isModalShown: PT.bool,
    closeModal: PT.func,
    office: PT.string,
};

export default MapPopUpReserve;
