import { useMemo } from 'react';
import { useAsync } from 'react-use';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';

const useAvatar = () => {
    const { token } = useAccessToken();

    const {
        loading,
        error,
        value: url,
    } = useAsync(async () => {
        if (!token) {
            return;
        }
        const uri = `${C.graphMeV1}/photo/$value`;

        return await fetchFromBe({ uri, token, type: 'blob' });
    }, [token]);

    return useMemo(() => ({ url, loading, error }), [url, loading, error]);
};

export default useAvatar;
