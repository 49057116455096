import { useMemo } from 'react';
import { useAsync } from 'react-use';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import C from 'core/global.conf.js';
import logger from 'utils/logger.util.js';

const L = logger('useAccessToken');

const SCOPE_USER = 'user.read';
const SCOPE_BE = `api://${C.clientId}/user_impersonation`;

const useAccessToken = (type = C.apiType.user) => {
    const { instance, accounts } = useMsal();
    const { loading, error, value } = useAsync(async () => {
        const request = { scopes: [type === C.apiType.user ? SCOPE_USER : SCOPE_BE], account: accounts[0] };

        const result = await instance
            .acquireTokenSilent(request)
            .catch((reason) =>
                error instanceof InteractionRequiredAuthError
                    ? instance.acquireTokenPopup(request)
                    : Promise.reject(reason),
            );

        return result?.accessToken;
    }, [instance, accounts]);

    if (error) {
        L.warn(error);
    }

    return useMemo(
        () => ({
            loading,
            error,
            token: value,
        }),
        [loading, error, value],
    );
};

export default useAccessToken;
