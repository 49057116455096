import { memo } from 'react';
import classNames from 'classnames';
import PT from 'prop-types';

import InfoIconScan from '../Icons/ScanIcon/InfoIconScan.component';

import CN from './ScanNoReservation.module.scss';

const ScanNoReservation = ({ children, className }) => {
    const SCAN_CLASSNAMES = classNames(CN.container, className);
    return (
        <div className={SCAN_CLASSNAMES}>
            <div className={CN.text}>
                <span className={CN.scanIcon}>
                    <InfoIconScan />
                </span>
                {children ?? 'You do not have a reservation for today.'}
            </div>
        </div>
    );
};

ScanNoReservation.propTypes = {
    children: PT.node,
    className: PT.string,
};

export default memo(ScanNoReservation);
