import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useToastDispatch } from 'context/ToastContext.js';
import { format } from 'date-fns';

import DeskPicker from 'components/common/DeskPicker/DeskPicker.component.jsx';
import InfoIcon from 'components/common/Icons/ScanIcon/InfoIcon.component.jsx';
import Loader from 'components/common/Loader/Loader.component.jsx';
import ScanNoReservationComponent from 'components/common/ScanNoReservation/ScanNoReservation.component';
import useEmployeesLoggedIn from 'hooks/api/Employees/useEmployesLoggedIn.hook.js';
import useGetLocations from 'hooks/api/Locations/useGetLocations.hook.js';
import useGetMyCurrentReservations from 'hooks/api/MyReservations/useGetMyCurrentReservations.hook.js';
import useUpdateMyReservations from 'hooks/api/MyReservations/useUpdateMyReservations.hook.js';
import unignore from 'utils/unignore.util.js';

import CN from './Scan.module.scss';

const Scan = () => {
    const { value: myCurrentReservation, loading = true } = useGetMyCurrentReservations();

    const { employeeLoggedInResult, employeeLoggedIn } = useEmployeesLoggedIn();
    const { loading: locationsLoading, value: locations } = useGetLocations();

    const [code, setCode] = useState('');
    const [office, setOffice] = useState(null);
    const [myLocation, seyMylocation] = useState([]);
    const [selectedSeat, setSelectedSeat] = useState({});
    const [employee, setEmployee] = useState({});
    const dispatchToast = useToastDispatch();

    const CONTAINER_CLASSES = classNames('d-flex', CN.container);
    const OFFICE_CONTAINER_CLASSES = classNames('d-flex', 'flex-center', CN.pt35);

    const onConfirmSuccess = useCallback(
        (code, response) => {
            if (response.succeeded) {
                dispatchToast({
                    type: 'success',
                    message: 'Your code was scaned successfully.',
                    duration: 2000,
                });
                setOffice(null);
            } else {
                dispatchToast({
                    type: 'error',
                    message: 'Your code was incorect, please try again.',
                    duration: 4000,
                });
            }
        },
        [dispatchToast],
    );

    useEffect(() => {
        employeeLoggedIn();
    }, [employeeLoggedIn]);
    useEffect(() => void (employeeLoggedInResult && setEmployee(employeeLoggedInResult)), [employeeLoggedInResult]);

    const [{ errors: errorsUpdate }, updateMyReservation] = useUpdateMyReservations(onConfirmSuccess);
    // TODO: refactor useEffect
    useEffect(() => {
        if (
            locations &&
            Object.keys(locations).length > 0 &&
            myCurrentReservation &&
            Object.keys(myCurrentReservation).length > 0
        ) {
            const [myLocations] = locations.data.filter((locations) => {
                return locations.offices
                    ? locations?.offices?.filter((office) => {
                          return office.id === myCurrentReservation?.data?.office?.id;
                      })
                    : [];
            });

            seyMylocation(myLocations);

            const [myOffice] = myLocations.offices.filter((office) => {
                return office.id === myCurrentReservation?.data?.office?.id;
            });

            setOffice(myOffice);
        }
    }, [locations, myCurrentReservation, myLocation]);

    function handleCodeChange(e) {
        setCode(e.target.value);
    }

    const handleClick = () => {
        if (code.trim() !== '') {
            unignore('Scan.updateMyReservation()', updateMyReservation(code));
        }
    };

    return (
        !locationsLoading && (
            <div className={CN.contents}>
                <div className={CONTAINER_CLASSES}>
                    {myCurrentReservation && office && (
                        <>
                            <DeskPicker
                                chosenDate={[format(new Date(), 'yyyy-MM-dd')]}
                                locationId={myLocation.id}
                                office={office}
                                officeId={myCurrentReservation?.data?.office?.id}
                                selectedSeat={selectedSeat}
                                setSelectedSeat={setSelectedSeat}
                                employeeId={employee?.id}
                                scanDisabled
                            />
                        </>
                    )}
                    <span className={CN.text}>
                        {loading ? (
                            <Loader />
                        ) : office ? (
                            `Today's desk: ${myCurrentReservation?.data?.deskCode}`
                        ) : (
                            <>
                                <InfoIcon />
                                <ScanNoReservationComponent />
                            </>
                        )}
                    </span>
                    {office && (
                        <div className={OFFICE_CONTAINER_CLASSES}>
                            <input
                                className={CN.scan}
                                type="text"
                                value={code}
                                onChange={handleCodeChange}
                                placeholder="Insert code:"
                            />
                            <button className={CN.button} onClick={handleClick}>
                                Scan
                            </button>
                        </div>
                    )}
                    <br />
                    {errorsUpdate && <span>{errorsUpdate.message}</span>}
                </div>
            </div>
        )
    );
};

Scan.propTypes = {};

export default Scan;
