import classNames from 'classnames';
import PT from 'prop-types';

import Button from 'components/common/Button/Button.component.jsx';
import LoginLogo from 'components/common/LoginLogo/LoginLogo.component.jsx';
import useLogIn from 'hooks/useLogIn.hook.js';
import logger from 'utils/logger.util.js';

import CN from './Login.module.scss';

const L = logger('Navbar');

const Login = () => {
    const { login, loginError } = useLogIn();
    const CONTAINER_CLASSES = classNames('d-flex', 'flex-center', CN.container);

    if (loginError) {
        L.warn({ loginError });
    }

    return (
        <main data-testid="login" className={CONTAINER_CLASSES}>
            <LoginLogo className={CN.logo} />
            <p className={CN.text}>By Melon, part of Kin+Carta</p>
            <Button link className={CN.logInButton} onClick={login}>
                Log In
            </Button>
        </main>
    );
};

Login.propTypes = {
    children: PT.node,
};

export default Login;
