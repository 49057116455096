import { createContext, useContext, useEffect, useReducer } from 'react';
import PT from 'prop-types';

const LocationContext = createContext(null);

const LocationDispatchContext = createContext(null);

const locationReducer = (state, action) => {
    if (state !== action.location) {
        sessionStorage.setItem('location', action.location);
        return action.location;
    } else {
        sessionStorage.setItem('location', state);
        return state;
    }
};

export const LocationProvider = ({ children }) => {
    const initialState = sessionStorage.getItem('location');
    const [state, dispatch] = useReducer(locationReducer, initialState);

    useEffect(() => {
        return () => {
            sessionStorage.removeItem('location');
        };
    }, []);

    return (
        <LocationContext.Provider value={state}>
            <LocationDispatchContext.Provider value={dispatch}>{children}</LocationDispatchContext.Provider>
        </LocationContext.Provider>
    );
};

export const useLocation = () => {
    return useContext(LocationContext);
};

export const useLocationDispatch = () => {
    return useContext(LocationDispatchContext);
};

LocationProvider.propTypes = {
    children: PT.node.isRequired,
};
