import { useMemo } from 'react';
import { useReports } from 'context/ReportsContext';
import { format } from 'date-fns';

import LoaderComponent from 'components/common/Loader/Loader.component';

import CN from './ReportsTable.module.scss';

const ReportsTable = () => {
    const {
        reportsTableData: { reportsData, reportsError, reportsLoading },
    } = useReports();

    const data = useMemo(
        () =>
            reportsLoading ? (
                <tr className={CN.empty}>
                    <td colSpan={8}>
                        <LoaderComponent />
                    </td>
                </tr>
            ) : (
                reportsData?.data?.map(
                    ({ id, employeeName, date, locationName, officeName, deskCode, notes, adminNotes, status }) => (
                        <tr key={id}>
                            <td>{employeeName}</td>
                            <td>{format(new Date(date), 'dd/MM/yyyy')}</td>
                            <td>{locationName}</td>
                            <td>{officeName}</td>
                            <td>{deskCode}</td>
                            <td>{notes ?? ''}</td>
                            <td>{adminNotes ?? ''}</td>
                            <td>{status?.replace(/_/g, ' ')}</td>
                        </tr>
                    ),
                )
            ),
        [reportsData, reportsLoading],
    );

    return (
        <div className={CN.container}>
            <table>
                <thead>
                    <tr>
                        <th>Employee</th>
                        <th>Date</th>
                        <th>Location</th>
                        <th>Room</th>
                        <th>Desk</th>
                        <th>User note</th>
                        <th>Admin note</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {reportsError ? (
                        <tr className={CN.empty}>
                            <td colSpan={8}>There has been an error. Please try again.</td>
                        </tr>
                    ) : (
                        data
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ReportsTable;
