import { memo } from 'react';
import classNames from 'classnames';
import PT from 'prop-types';

import CN from './Loader.module.scss';

const Loader = ({ position, className }) => {
    const LOADER_CLASSNAMES = classNames(CN.loader, position === 'absolute' ? CN.absolute : '', className);
    return (
        <div className={LOADER_CLASSNAMES}>
            <div className={CN.spinner}></div>
        </div>
    );
};

Loader.propTypes = {
    position: PT.oneOf(['static', 'absolute']),
    className: PT.string,
};

export default memo(Loader);
