import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import PT from 'prop-types';

import useEmployees from 'hooks/api/Employees/useEmployees.hook';
import useGetLocations from 'hooks/api/Locations/useGetLocations.hook';
import useGetReports from 'hooks/api/Reports/useGetReports.hook';

const PAGE_NUMBER = 1;
const PAGE_SIZE = 100;
const SORT_ORDER = 0;

const REPORTS_STATE = {
    locations: {},
    selectedLocation: {},
    employees: {},
    selectedEmployee: [],
    officesList: [],
    selectedOffice: {},
    desksList: [],
    selectedDesk: {},
    dateFrom: '',
    dateTo: '',
    statusList: [
        { value: 'AUTO-CANCELLED', label: 'Auto - cancelled' },
        { value: 'ACTIVE', label: 'Active' },
        { value: 'DONE', label: 'Done' },
        { value: '', label: 'All statuses' },
    ],
    status: '',
};

const reportsActions = {
    getLocations: 'GET_LOCATIONS',
    selectLocation: 'SELECT_LOCATION',
    getEmployees: 'GET_EMPLOYEES',
    selectEmployee: 'SELECT_EMPLOYEE',
    getOffices: 'GET_OFFICES',
    selectOffice: 'SELECT_OFFICE',
    getDesks: 'GET_DESKS',
    selectDesk: 'SELECT_DESK',
    selectDateFrom: 'SELECT_DATE_FROM',
    selectDateTo: 'SELECT_DATE_TO',
    selectStatus: 'SELECT_STATUS',
    resetState: 'RESET_STATE',
};

const ReportsContext = createContext(null);
const ReportsDispatchContext = createContext(null);

const reportsReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case reportsActions.getLocations:
            return {
                ...state,
                locations: {
                    ...payload,
                    value: payload.value?.data,
                },
            };
        case reportsActions.selectLocation:
            return { ...state, selectedLocation: { ...payload } };
        case reportsActions.getEmployees:
            return {
                ...state,
                employees: {
                    ...payload,
                    value: payload?.value
                        ?.filter(({ firstName, lastName }) => firstName && lastName)
                        .map(({ id, firstName, lastName }) => ({ id, name: `${firstName} ${lastName}` })),
                },
            };
        case reportsActions.selectEmployee:
            return { ...state, selectedEmployee: Object.keys(payload).length > 0 ? [payload] : state.employees?.value };
        case reportsActions.getOffices:
            return {
                ...state,
                officesList: state.locations?.value?.find(({ id }) => id === state.selectedLocation.id)?.offices || [],
            };
        case reportsActions.selectOffice:
            return { ...state, selectedOffice: { ...payload } };
        case reportsActions.getDesks:
            return {
                ...state,
                desksList: state.officesList?.find(({ id }) => id === state.selectedOffice.id)?.desks || [],
            };
        case reportsActions.selectDesk:
            return { ...state, selectedDesk: { ...payload } };
        case reportsActions.selectDateFrom:
            return { ...state, dateFrom: payload };
        case reportsActions.selectDateTo:
            return { ...state, dateTo: payload };
        case reportsActions.selectStatus:
            return { ...state, status: payload };
        case reportsActions.resetState:
            return { ...state };
        default:
            return state;
    }
};

export const ReportsProvider = ({ children }) => {
    const locations = useGetLocations();
    const employees = useEmployees();
    const [state, dispatch] = useReducer(reportsReducer, REPORTS_STATE);
    const dateFromFormatted = state.dateFrom?.split('/').reverse().join('-').concat('T00:00:00');
    const dateToFormatted = state.dateTo?.split('/').reverse().join('-').concat('T00:00:00');
    const employeesIdsFormatted =
        state.selectedEmployee?.length > 0 &&
        state.selectedEmployee.filter(({ id }) => id !== '' && id).map(({ id }) => id);
    const { getReports, reportsData, reportsError, reportsLoading } = useGetReports();

    const body = useMemo(
        () => ({
            locationId: state.selectedLocation?.id,
            officeId: state.selectedOffice?.id,
            deskId: state.selectedDesk?.id,
            periodFrom: dateFromFormatted,
            periodTo: dateToFormatted,
            status: state.status?.value,
            employeeIds: employeesIdsFormatted,
            orderBy: '',
            pageNumber: PAGE_NUMBER,
            pageSize: PAGE_SIZE,
            sortOrder: SORT_ORDER,
        }),
        [
            dateFromFormatted,
            dateToFormatted,
            state.selectedLocation?.id,
            state.selectedOffice?.id,
            state.selectedDesk?.id,
            employeesIdsFormatted,
            state.status?.value,
        ],
    );

    const getTableData = useCallback(async () => {
        await getReports(body);
        dispatch({ type: reportsActions.resetState });
    }, [getReports, body]);

    const getLocations = useCallback(() => {
        locations &&
            dispatch({
                type: reportsActions.getLocations,
                payload: locations,
            });
    }, [locations]);

    const getEmployees = useCallback(
        () =>
            employees &&
            dispatch({
                type: reportsActions.getEmployees,
                payload: employees,
            }),
        [employees],
    );

    useEffect(() => {
        getLocations();
    }, [getLocations]);

    useEffect(() => {
        getEmployees();
    }, [getEmployees]);

    return (
        <ReportsContext.Provider value={{ state, reportsTableData: { reportsData, reportsError, reportsLoading } }}>
            <ReportsDispatchContext.Provider value={{ dispatch, getTableData }}>
                {children}
            </ReportsDispatchContext.Provider>
        </ReportsContext.Provider>
    );
};

export const useReports = () => {
    return useContext(ReportsContext);
};

export const useReportsDispatch = () => {
    return useContext(ReportsDispatchContext);
};

export const REPORTS_ACTIONS = reportsActions;

ReportsProvider.propTypes = {
    children: PT.node.isRequired,
};
