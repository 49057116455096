const InfoIcon = () => (
    <svg width="313" height="291" viewBox="0 0 313 291" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M211.339 96.7139H98.0008C88.6115 96.7139 81 104.343 81 113.754V215.997C81 225.409 88.6115 233.038 98.0008 233.038H211.339C220.729 233.038 228.34 225.409 228.34 215.997V113.754C228.34 104.343 220.729 96.7139 211.339 96.7139Z"
            stroke="#A5A8AA"
            strokeWidth="10"
            strokeLinejoin="round"
        />
        <path
            d="M109.335 85.6978V96.7138M200.006 85.6978V96.7138M228.34 124.254H81"
            stroke="#A5A8AA"
            strokeWidth="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M280.25 154C280.25 159.036 275.959 163.25 270.5 163.25C265.041 163.25 260.75 159.036 260.75 154C260.75 148.964 265.041 144.75 270.5 144.75C275.959 144.75 280.25 148.964 280.25 154Z"
            stroke="#A5A8AA"
            strokeWidth="3.5"
        />
        <circle cx="15.5" cy="173.5" r="13.75" stroke="#A5A8AA" strokeWidth="3.5" />
        <circle cx="266.5" cy="33.5" r="7.5" fill="#A5A8AA" />
        <rect
            x="167.14"
            y="290.424"
            width="18.547"
            height="4.05716"
            rx="2.02858"
            transform="rotate(-81.8363 167.14 290.424)"
            fill="#A5A8AA"
        />
        <circle cx="147.5" cy="7.5" r="7.5" fill="#A5A8AA" />
        <rect
            x="42.1266"
            y="40.8945"
            width="19.1625"
            height="4.19181"
            rx="2.0959"
            transform="rotate(-129.55 42.1266 40.8945)"
            fill="#A5A8AA"
        />
        <rect
            x="280.975"
            y="210.293"
            width="19.616"
            height="4.29099"
            rx="2.1455"
            transform="rotate(25.6857 280.975 210.293)"
            fill="#A5A8AA"
        />
        <rect
            x="189.619"
            y="269.873"
            width="19.616"
            height="4.29099"
            rx="2.1455"
            transform="rotate(70.9993 189.619 269.873)"
            fill="#A5A8AA"
        />
        <rect
            x="286.743"
            y="194.612"
            width="19.616"
            height="4.29099"
            rx="2.1455"
            transform="rotate(9.68565 286.743 194.612)"
            fill="#A5A8AA"
        />
        <circle cx="255.5" cy="261.5" r="10.5" fill="#A5A8AA" />
        <rect
            x="16.7547"
            y="45.5469"
            width="16.8851"
            height="3.69362"
            rx="1.84681"
            transform="rotate(23.0944 16.7547 45.5469)"
            fill="#A5A8AA"
        />
        <circle cx="97.5" cy="283.5" r="7.5" fill="#A5A8AA" />
        <path
            d="M208.25 41C208.25 48.2641 202.148 54.25 194.5 54.25C186.852 54.25 180.75 48.2641 180.75 41C180.75 33.7359 186.852 27.75 194.5 27.75C202.148 27.75 208.25 33.7359 208.25 41Z"
            stroke="#A5A8AA"
            strokeWidth="3.5"
        />
        <circle
            cx="37.2931"
            cy="100.439"
            r="9.75234"
            transform="rotate(42.0012 37.2931 100.439)"
            stroke="#A5A8AA"
            strokeWidth="3.5"
        />
        <circle cx="86.3695" cy="36.4999" r="10.5243" transform="rotate(-83.224 86.3695 36.4999)" fill="#A5A8AA" />
        <circle cx="41.7619" cy="238.717" r="10.5243" transform="rotate(-83.224 41.7619 238.717)" fill="#A5A8AA" />
        <circle cx="297.024" cy="99.3111" r="14.283" transform="rotate(-83.224 297.024 99.3111)" fill="#A5A8AA" />
    </svg>
);

export default InfoIcon;
