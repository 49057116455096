import { REPORTS_ACTIONS, useReports, useReportsDispatch } from 'context/ReportsContext';

import DateAutocomplete from 'components/common/DateAutocomplete/DateAutocomplete.component';

const DateFrom = () => {
    const {
        state: { dateFrom, dateTo },
    } = useReports();
    const { dispatch } = useReportsDispatch();

    const formattedDate = dateTo?.split('/').reverse().join('-');
    const disabledDays = [{ after: new Date(formattedDate) }];

    const setDate = (value) => {
        dispatch({ type: REPORTS_ACTIONS.selectDateFrom, payload: value });
    };

    return <DateAutocomplete value={dateFrom} onSelect={setDate} placeholder="Date from:" disabled={disabledDays} />;
};

export default DateFrom;
