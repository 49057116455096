import officeMapb1 from 'assets/officesMaps/skopjeOfficesMaps/b1.svg';
import officeMapb2 from 'assets/officesMaps/skopjeOfficesMaps/b2.svg';
import officeMapb3 from 'assets/officesMaps/skopjeOfficesMaps/b3.svg';
import officeMapb4 from 'assets/officesMaps/skopjeOfficesMaps/b4.svg';
import officeMapb5 from 'assets/officesMaps/skopjeOfficesMaps/b5.svg';
import officeMapb6 from 'assets/officesMaps/skopjeOfficesMaps/b6.svg';
import officeMapb7 from 'assets/officesMaps/skopjeOfficesMaps/b7.svg';
import officeMapb8 from 'assets/officesMaps/skopjeOfficesMaps/b8.svg';

const OFFICES = [
    {
        id: '6a41adee-7da3-4a39-8aa8-082d56579664',
        offices: [
            {
                name: 'B1',
                map: officeMapb1,
            },
            {
                name: 'B2',
                map: officeMapb2,
            },
            {
                name: 'B3',
                map: officeMapb3,
            },
            {
                name: 'B4',
                map: officeMapb4,
            },
            {
                name: 'B5',
                map: officeMapb5,
            },
            {
                name: 'B6',
                map: officeMapb6,
            },
            {
                name: 'B7',
                map: officeMapb7,
            },
            {
                name: 'B8',
                map: officeMapb8,
            },
        ],
    },
    //insert other offices maps here
];

export default OFFICES;
