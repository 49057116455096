import { useAsyncFn } from 'react-use';
import { isFunction } from 'lodash/fp';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';

const useDeleteMyReservations = (onSuccess) => {
    const type = 'json';
    const method = 'DELETE';
    const { token } = useAccessToken(C.apiType.backend);

    return useAsyncFn(
        async (reservationId) => {
            if (!token) {
                return;
            }

            const params = new URLSearchParams({
                reservationId,
            });

            const uri = `${C.baseBeUrl}/my-reservations/cancel`;

            const promise = fetchFromBe({ uri, token, type, params, method });

            if (isFunction(onSuccess)) {
                promise.then((response) => onSuccess({ reservationId }, response));
            }
            return await promise;
        },
        [token],
    );
};

export default useDeleteMyReservations;
