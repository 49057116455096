import { useCallback, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import { useToastDispatch } from 'context/ToastContext';
import PT from 'prop-types';

import CN from './ReservationsTableTextarea.module.scss';

const ReservationsTableTextarea = ({ confirmByAdmin, id, placeholder, deskCode, notes, onUpdate, disabled }) => {
    const [note, setNote] = useState(notes);
    const dispatchToast = useToastDispatch();
    // const textArea = document.querySelector('textarea');
    // const textRowCount = textArea ? textArea.value.split('\n').length : 0;
    // const rows = textRowCount + 1;
    const handleChange = (e) => {
        setNote(e.target.value);
    };

    const handleUpdate = useCallback(() => {
        if (notes !== note) {
            if (note.length <= 100) {
                onUpdate(id, note, confirmByAdmin, deskCode);
            } else {
                dispatchToast({
                    type: 'error',
                    message: 'Maximum 100 characters allowed',
                    duration: 4000,
                });
            }
        }
    }, [notes, onUpdate, id, note, confirmByAdmin, deskCode, dispatchToast]);

    return (
        <TextareaAutosize
            className={CN.fullWidth}
            disabled={disabled}
            value={note || ''}
            onBlur={handleUpdate}
            onChange={handleChange}
            minRows={1}
            maxRows={3}
            style={{ minHeight: '3vh' }}
            maxLength="100"
            placeholder={placeholder}
        />
    );
};

ReservationsTableTextarea.propTypes = {
    confirmByAdmin: PT.bool,
    deskCode: PT.string,
    onUpdate: PT.func,
    id: PT.string,
    placeholder: PT.string,
    notes: PT.string,
    disabled: PT.bool,
};

export default ReservationsTableTextarea;
