import classNames from 'classnames';
import PT from 'prop-types';

import XButton from '../XButton/XButton.component';

import CN from './Modal.module.scss';

const Modal = ({ children, isModalShown, closeModal, confirm = false }) => {
    const BACKGROUND_CLASSNAMES = classNames(CN.background, isModalShown && CN.showModal);
    const MODAL_CLASSNAMES = classNames(
        CN.modal,
        isModalShown && CN.showModal,
        confirm ? CN.confirmModal : CN.cancelModal,
    );
    return (
        <>
            <div className={BACKGROUND_CLASSNAMES}></div>
            <div className={MODAL_CLASSNAMES}>
                {children}
                <XButton onClick={closeModal} />
            </div>
        </>
    );
};

Modal.propTypes = {
    children: PT.node,
    closeModal: PT.func,
    isModalShown: PT.bool,
    confirm: PT.bool,
};
export default Modal;
