import { StrictMode } from 'react';
import { isMobile } from 'react-device-detect';
import { BrowserRouter } from 'react-router-dom';
import { LocationProvider } from 'context/LocationContext';
import { ToastProvider } from 'context/ToastContext';

import NoInternetScreenComponent from 'components/common/NoInternetConnectionScreen/NoInternetScreen.component';
import MainToaster from 'components/common/Toaster/MainToaster.component';
import Auth from 'components/layout/Auth/Auth.component.jsx';
import AuthenticatedContent from 'components/layout/Auth/AuthenticatedContent.component.jsx';
import UnauthenticatedContent from 'components/layout/Auth/UnauthenticatedContent.component.jsx';
import Login from 'components/layout/Login/Login.component.jsx';
import Main from 'components/layout/Main/Main.component.jsx';
import Navbar from 'components/layout/Navbar/Navbar.component.jsx';

import MobileView from '../MobileView/MobileView.component';

const App = () => {
    return (
        <StrictMode>
            <Auth>
                <ToastProvider>
                    <LocationProvider>
                        <BrowserRouter>
                            <AuthenticatedContent>
                                {isMobile && <MobileView />}
                                {!isMobile && (
                                    <>
                                        <Navbar />
                                        <NoInternetScreenComponent />
                                        <Main />
                                        <MainToaster />
                                    </>
                                )}
                            </AuthenticatedContent>
                            <UnauthenticatedContent>
                                {isMobile && <MobileView />}
                                {!isMobile && <Login />}
                            </UnauthenticatedContent>
                        </BrowserRouter>
                    </LocationProvider>
                </ToastProvider>
            </Auth>
        </StrictMode>
    );
};

export default App;
