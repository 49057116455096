import { useMemo } from 'react';
import { useAsync } from 'react-use';
import { useMsal } from '@azure/msal-react';

import logger from 'utils/logger.util';

const L = logger('useGetRole');

export const useGetRole = () => {
    const { instance } = useMsal();
    const { loading, error, value } = useAsync(async () => {
        const result = await instance.getActiveAccount();
        return result?.idTokenClaims?.roles ? result?.idTokenClaims?.roles : false;
    }, [instance]);

    if (error) {
        L.warn(error);
    }

    return useMemo(
        () => ({
            loading,
            error,
            role: value,
        }),
        [loading, error, value],
    );
};

export default useGetRole;
