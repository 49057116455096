import { createContext, useContext, useReducer } from 'react';
import PT from 'prop-types';

const ToastContext = createContext(null);

const ToastDispatchContext = createContext(null);

const toastReducer = (toastState, action) => ({
    type: action.type,
    message: action.message,
    duration: action.duration,
});

export const ToastProvider = ({ children }) => {
    const [toastState, dispatch] = useReducer(toastReducer);

    return (
        <ToastContext.Provider value={toastState}>
            <ToastDispatchContext.Provider value={dispatch}>{children}</ToastDispatchContext.Provider>
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    return useContext(ToastContext);
};

export const useToastDispatch = () => {
    return useContext(ToastDispatchContext);
};

ToastProvider.propTypes = {
    children: PT.node.isRequired,
};
