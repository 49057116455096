import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import PT from 'prop-types';

import CN from 'components/common/SeatButton/SeatButton.module.scss';
import determineDeskState from 'utils/determineDeskState.util.js';

//renders image tag and must be a child of svg tag
const SeatButton = ({ desk, setSelectedSeat, selectedSeat, hoverOver, hoverOut, employeeId, scanDisabled = false }) => {
    const [deskState, setDeskState] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);

    const buttonClassNames = classNames(CN.button, !isDisabled && CN.enabledButton);

    useEffect(() => {
        const result = determineDeskState({ desk, seat: selectedSeat, employeeId });

        setDeskState(result.img);
        if (scanDisabled || result.reserved) setIsDisabled(true);

        setIsDisabled(result.reserved);
    }, [desk, selectedSeat, employeeId, scanDisabled]);

    const handleClick = useCallback(() => {
        setSelectedSeat(desk);

        const result = determineDeskState({ desk, seat: selectedSeat, employeeId });

        if (scanDisabled || result.reserved) {
            setSelectedSeat({});
            setIsDisabled(true);
            return;
        }
        setDeskState(result.img);
    }, [setSelectedSeat, desk, selectedSeat, employeeId, scanDisabled]);
    const CustomizedTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} placement={'right'} />
    ))(`
          color: black;
          background-color: #efefef;
          font-size: 1em;
          font-weight: bold;
      `);

    return (
        <>
            {desk && (
                <CustomizedTooltip title={desk.code}>
                    <image
                        width={desk.isHorisontal ? '9%' : '8%'}
                        height={desk.isHorisontal ? '8%' : '9%'}
                        x={`${desk.x}px`}
                        y={`${desk.y}px`}
                        href={deskState}
                        className={buttonClassNames}
                        onClick={handleClick}
                        onMouseOver={() => hoverOver(desk.id)}
                        onMouseLeave={hoverOut}
                    />
                </CustomizedTooltip>
            )}
        </>
    );
};

SeatButton.propTypes = {
    desk: PT.object,
    setSelectedSeat: PT.func,
    selectedSeat: PT.object || null,
    hoverOver: PT.func,
    hoverOut: PT.func,
    myReservations: PT.object,
    employeeId: PT.string,
    scanDisabled: PT.bool,
};

export default SeatButton;
