import { REPORTS_ACTIONS, useReports, useReportsDispatch } from 'context/ReportsContext';

import DateAutocomplete from 'components/common/DateAutocomplete/DateAutocomplete.component';

const DateTo = () => {
    const {
        state: { dateFrom, dateTo },
    } = useReports();
    const { dispatch } = useReportsDispatch();

    const formattedDate = dateFrom?.split('/').reverse().join('-');
    const disabledDays = [{ before: new Date(formattedDate) }];

    const setDate = (value) => {
        dispatch({ type: REPORTS_ACTIONS.selectDateTo, payload: value });
    };

    return <DateAutocomplete value={dateTo} onSelect={setDate} placeholder="Date to:" disabled={disabledDays} />;
};

export default DateTo;
