import { createContext, useContext, useEffect, useReducer } from 'react';
import PT from 'prop-types';

import useGetMyReservations from 'hooks/api/MyReservations/useGetMyReservations.hook';

const MyReservationContext = createContext(null);
const MyReservationDispatchContext = createContext(null);

const reservationReducer = (state, action) => {
    if (action.type === 'FETCH') {
        return action.reservations;
    } else if (action.type === 'UPDATE') {
        return [
            ...state.filter((reservation) => reservation.id !== action.reservation.reservationId),
            {
                ...state.filter((reservation) => reservation.id === action.reservation.reservationId)[0],
                confirmByAdmin: action.reservation.confirmByAdmin,
                adminNotes: action.reservation.adminNotes,
            },
        ];
    } else if (action.type === 'CANCEL') {
        return [...state.filter((reservation) => reservation.id !== action.reservation.reservationId)];
    } else {
        return state;
    }
};

export const MyReservationProvider = ({ children }) => {
    const { value: myReservations } = useGetMyReservations();
    const [state, dispatch] = useReducer(reservationReducer);
    const hasReservations = myReservations && Object.keys(myReservations).length > 0;

    useEffect(() => {
        if (hasReservations) {
            const newReservations = myReservations.data
                .map((item) => {
                    return { ...item, officeName: item.office.name };
                })
                .filter((item) => item.status === 'ACTIVE' || item.status === 'CONFIRMED');
            dispatch({ type: 'FETCH', reservations: newReservations });
        }
    }, [myReservations, hasReservations]);

    return (
        state && (
            <MyReservationContext.Provider value={state}>
                <MyReservationDispatchContext.Provider value={dispatch}>
                    {children}
                </MyReservationDispatchContext.Provider>
            </MyReservationContext.Provider>
        )
    );
};

export const useMyReservation = () => {
    return useContext(MyReservationContext);
};

export const useMyReservationDispatch = () => {
    return useContext(MyReservationDispatchContext);
};

MyReservationProvider.propTypes = {
    children: PT.node.isRequired,
};
