import cantBeReservedSeat from 'assets/icons/seatsIcons/cantBeReservedSeat.svg';
import cantBeReservedSeatHorizontal from 'assets/icons/seatsIcons/cantBeReservedSeatHorizontal.svg';
import freeSeat from 'assets/icons/seatsIcons/freeSeat.svg';
import freeSeatHorizontal from 'assets/icons/seatsIcons/freeSeatHorizontal.svg';
import mySeat from 'assets/icons/seatsIcons/mySeat.svg';
import mySeatHorizontal from 'assets/icons/seatsIcons/mySeatHorizontal.svg';
import selectedSeat from 'assets/icons/seatsIcons/selectedSeat.svg';
import selectedSeatHorizontal from 'assets/icons/seatsIcons/selectedSeatHorizontal.svg';
import takenSeat from 'assets/icons/seatsIcons/takenSeat.svg';
import takenSeatHorizontal from 'assets/icons/seatsIcons/takenSeatHorizontal.svg';

const STATES = Object.freeze({
    h: Object.freeze({
        free: freeSeatHorizontal,
        selected: selectedSeatHorizontal,
        taken: takenSeatHorizontal,
        mine: mySeatHorizontal,
        unreservable: cantBeReservedSeatHorizontal,
    }),
    v: Object.freeze({
        free: freeSeat,
        selected: selectedSeat,
        taken: takenSeat,
        mine: mySeat,
        unreservable: cantBeReservedSeat,
    }),
});

const determineDeskState = ({
    desk: { id: deskId, isHorizontal, canBeReserved, reservations },
    seat: { id: seatId = {} },
    employeeId,
}) => {
    const direction = isHorizontal ? 'h' : 'v';

    if (!canBeReserved) {
        const state = 'unreservable';
        return {
            img: STATES[direction][state],
            reserved: true,
            direction,
            selected: false,
            state,
        };
    }

    if (seatId === deskId) {
        const state = 'selected';
        return {
            img: STATES[direction][state],
            reserved: canBeReserved,
            direction,
            selected: true,
            state,
        };
    }

    for (const { status } of reservations ?? []) {
        if (status === 'AUTO_CANCELLED') {
            return {
                img: STATES[direction].free,
                reserved: false,
                direction,
                selected: false,
                state: 'free',
            };
        }
        if (reservations[0] && employeeId) {
            const state = reservations[0].employee.id === employeeId ? 'mine' : 'taken';
            return {
                img: STATES[direction][state],
                reserved: true,
                direction,
                selected: false,
                state,
            };
        }
    }

    return {
        img: STATES[direction].free,
        reserved: false,
        direction,
        selected: false,
        state: 'free',
    };
};

export default determineDeskState;
