import useGetLocations from 'hooks/api/Locations/useGetLocations.hook';

import LocationSVG from './LocationSVG.component';

import CN from './Home.module.scss';

const Home = () => {
    const { loading, value } = useGetLocations();

    const locationsMap = value?.data.map((location, index) => (
        <LocationSVG key={index} index={index + 1} name={location.name} id={location.id} />
    ));

    return <section className={CN.background}>{!loading && locationsMap}</section>;
};

Home.propTypes = {};

export default Home;
