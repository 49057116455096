import { useIsAuthenticated } from '@azure/msal-react';
import PT from 'prop-types';

const UnauthenticatedContent = ({ children }) => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return null;
    }

    return <>{children}</>;
};

UnauthenticatedContent.propTypes = {
    children: PT.node,
};

export default UnauthenticatedContent;
