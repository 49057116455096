import { useCallback } from 'react';
import { REPORTS_ACTIONS, useReports, useReportsDispatch } from 'context/ReportsContext';

import Autocomplete from 'components/common/Autocomplete/Autocomplete.component';

const EmployeesAutocomplete = () => {
    const {
        state: { employees },
    } = useReports();
    const { dispatch } = useReportsDispatch();

    const employeesList = employees?.value?.map(({ id, name }) => ({ value: id, label: name }));
    const listWithInsertedAllEmployeesOption =
        (employeesList?.length > 0 && [{ value: 'all-employees', label: 'All employees' }, ...employeesList]) || [];

    const handleSelectEmployee = useCallback(
        ({ value, label }) => {
            dispatch({
                type: REPORTS_ACTIONS.selectEmployee,
                payload: value === 'all-employees' ? {} : { id: value, name: label },
            });
        },
        [dispatch],
    );

    return (
        <Autocomplete
            options={listWithInsertedAllEmployeesOption}
            loading={employees.loading}
            placeholder="Search employee:"
            onSelect={handleSelectEmployee}
        />
    );
};

export default EmployeesAutocomplete;
