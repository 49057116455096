import { useAsyncFn } from 'react-use';
import { isFunction } from 'lodash/fp';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';

const useDeleteReservations = (onSuccess) => {
    const type = 'json';
    const method = 'DELETE';

    const { token } = useAccessToken(C.apiType.backend);

    return useAsyncFn(
        async (body) => {
            if (!token) {
                return;
            }

            const uri = `${C.baseBeUrl}/reservations/cancel`;

            const promise = fetchFromBe({ uri, token, type, method, body });

            if (isFunction(onSuccess)) {
                promise.then((response) => onSuccess(JSON.parse(body), response));
            }
            return await promise;
        },
        [token],
    );
};

export default useDeleteReservations;
