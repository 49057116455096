import { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'context/LocationContext.js';
import { useMyReservationDispatch } from 'context/MyReservationContext.js';
import { useReservationDispatch } from 'context/ReservationContext.js';
import { useToastDispatch } from 'context/ToastContext.js';
import { format } from 'date-fns';
import PT from 'prop-types';

import Avatar from 'components/common/Avatar/Avatar.component.jsx';
import CancelReservationModal from 'components/common/CancelReservationModal/CancelReservationModal.component.jsx';
import InfoIcon from 'components/common/Icons/ScanIcon/InfoIcon.component.jsx';
import Loader from 'components/common/Loader/Loader.component.jsx';
import ScanNoReservationComponent from 'components/common/ScanNoReservation/ScanNoReservation.component.jsx';
import DATES from 'core/date.const.js';
import useDeleteMyReservations from 'hooks/api/MyReservations/useDeleteMyReservations.hook.js';
import useUpdateMyReservations from 'hooks/api/MyReservations/useUpdateMyReservations.hook.js';
import useDeleteReservations from 'hooks/api/Reservations/useDeleteReservations.hook.js';
import useUpdateReservations from 'hooks/api/Reservations/useUpdateReservations.hook.js';
import unignore from 'utils/unignore.util.js';

import ReservationsTableCheckbox from './ReservationTableCheckbox.component.jsx';
import ReservationsTableTextarea from './ReservationTableTextarea.component.jsx';

import CN from './ReservationsTable.module.scss';

const ReservationsTable = ({ adminView, reservations }) => {
    const dispatchToast = useToastDispatch();
    const dispatchReservations = useReservationDispatch();
    const dispatchMyReservations = useMyReservationDispatch();
    const location = useLocation();
    const NO_DATA_CLASSES = classNames('d-flex', 'flex-center', CN.empty);

    const onCancelSuccess = useCallback(
        (reservation, response) => {
            if (response.succeeded) {
                dispatchReservations({
                    type: 'CANCEL',
                    reservation: reservation,
                });
                dispatchMyReservations({
                    type: 'CANCEL',
                    reservation: reservation,
                });
                dispatchToast({
                    type: 'success',
                    message: 'Reservation successfully cancelled.',
                    duration: 2000,
                });
            } else {
                dispatchToast({
                    type: 'error',
                    message: response.message,
                    duration: 4000,
                });
            }
        },
        [dispatchReservations, dispatchMyReservations, dispatchToast],
    );

    const onUpdateSuccess = useCallback(
        (reservation, response) => {
            if (response.succeeded) {
                dispatchReservations({
                    type: 'UPDATE',
                    reservation: reservation,
                });
                dispatchToast({
                    type: 'success',
                    message: 'Reservation successfully updated.',
                    duration: 2000,
                });
            } else {
                dispatchToast({
                    type: 'error',
                    message: response.message,
                    duration: 4000,
                });
            }
        },
        [dispatchReservations, dispatchToast],
    );

    const onCancelMyReservationSuccess = useCallback(
        (reservation, response) => {
            if (response.succeeded) {
                dispatchMyReservations({
                    type: 'CANCEL',
                    reservation: reservation,
                });
                dispatchReservations &&
                    dispatchReservations({
                        type: 'CANCEL',
                        reservation: reservation,
                    });
                dispatchToast({
                    type: 'success',
                    message: 'Reservation successfully cancelled.',
                    duration: 2000,
                });
            } else {
                dispatchToast({
                    type: 'error',
                    message: response.message,
                    duration: 4000,
                });
            }
        },
        [dispatchMyReservations, dispatchReservations, dispatchToast],
    );

    const onUpdateMyReservationSuccess = useCallback(
        (reservation, response) => {
            if (response.succeeded) {
                dispatchMyReservations({
                    type: 'UPDATE',
                    reservation: reservation,
                });
                dispatchToast({
                    type: 'success',
                    message: 'Reservation successfully updated.',
                    duration: 2000,
                });
            } else {
                dispatchToast({
                    type: 'error',
                    message: response.message,
                    duration: 4000,
                });
            }
        },
        [dispatchMyReservations, dispatchToast],
    );

    const [{ loading: loadingCancel, errors: errorsCancel }, cancelReservation] =
        useDeleteReservations(onCancelSuccess);
    const [{ loading: loadingUpdate, errors: errorsUpdate }, updateReservation] =
        useUpdateReservations(onUpdateSuccess);

    const [{ loading: loadingMyReservationsCancel, errors: errorsMyReservationsCancel }, cancelMyReservation] =
        useDeleteMyReservations(onCancelMyReservationSuccess);
    const [{ loading: loadingMyReservationsUpdate, errors: errorsMyReservationsUpdate }, updateMyReservation] =
        useUpdateMyReservations(onUpdateMyReservationSuccess);

    const [isModalShown, setIsModalShown] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState({});
    const [isOpened, setIsOpened] = useState(null);

    const isLoading = loadingUpdate || loadingCancel || loadingMyReservationsCancel || loadingMyReservationsUpdate;

    const openModal = (id, deskCode, officeName, date) => {
        const reservation = {
            id: id,
            desk: deskCode,
            office: officeName,
            dates: date,
        };

        setIsOpened(id);
        setSelectedReservation(reservation);
        setIsModalShown(true);
    };

    const closeModal = () => {
        setIsOpened(null);
        setIsModalShown(false);
    };

    const onCancel = (reservationId) => {
        if (adminView) {
            const body = JSON.stringify({ reservationId, locationId: location });
            unignore('ReservationTable.cancelReservation()', cancelReservation(body));
        } else {
            unignore('ReservationTable.cancelMyReservation()', cancelMyReservation(reservationId));
        }
        setIsModalShown(false);
    };

    const onUpdate = (reservationId, adminNotes, confirmByAdmin, deskCode) => {
        if (adminView) {
            const body = JSON.stringify([{ locationId: location, reservationId, confirmByAdmin, adminNotes }]);
            unignore('ReservationTable.updateReservation()', updateReservation(body));
        } else {
            unignore('ReservationTable.updateMyReservation()', updateMyReservation(deskCode));
        }
    };

    return (
        <div className={`${CN.p35} ${CN.scroll}`}>
            {isLoading && (
                <div className={CN.loader}>
                    <Loader />
                </div>
            )}

            {reservations.length === 0 ? (
                <div className={NO_DATA_CLASSES}>
                    <InfoIcon />
                    <ScanNoReservationComponent className={CN.textCenter}>
                        {'You don\'t have any active or confirmed reservations.'}
                    </ScanNoReservationComponent>
                </div>
            ) : (
                <table className={`${CN.textLeft} ${CN.table} ${CN['table-borderless']}`}>
                    <thead>
                        <tr>
                            {adminView && <td className={CN.gray}>Equipped</td>}
                            {adminView && (
                                <td>
                                    <span className={CN.gray}>Admin note </span>
                                    <span className={CN.lightGray}>(100 characters max)</span>
                                </td>
                            )}
                            <td className={CN.gray}>Employee</td>
                            <td className={CN.gray}>Room</td>
                            <td className={CN.gray}>Desk</td>
                            <td className={CN.gray}>Date</td>
                            <td className={CN.gray}>User note</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {reservations?.map(
                            ({
                                id,
                                employee,
                                deskCode,
                                date,
                                adminNotes,
                                notes,
                                confirmByAdmin,
                                officeName,
                                status,
                            }) => (
                                <tr key={id}>
                                    {adminView && (
                                        <td>
                                            <ReservationsTableCheckbox
                                                confirmByAdmin={confirmByAdmin}
                                                id={id}
                                                adminNotes={adminNotes}
                                                deskCode={deskCode}
                                                onUpdate={onUpdate}
                                            />
                                        </td>
                                    )}
                                    {adminView && (
                                        <td>
                                            <ReservationsTableTextarea
                                                confirmByAdmin={confirmByAdmin}
                                                id={id}
                                                notes={adminNotes}
                                                disabled={confirmByAdmin}
                                                placeholder="Admin note:"
                                                deskCode={deskCode}
                                                onUpdate={onUpdate}
                                            />
                                        </td>
                                    )}
                                    <td className="d-flex">
                                        {employee && (
                                            <Avatar
                                                className={CN.avatar}
                                                url={
                                                    employee.photoBase64
                                                        ? `data:image/png;base64, ${employee.photoBase64}`
                                                        : null
                                                }
                                                firstName={employee.firstName}
                                                lastName={employee.lastName}
                                                displayName={`${employee.firstName} ${employee.lastName}`}
                                            />
                                        )}
                                        {employee.firstName} {employee.lastName}
                                    </td>
                                    <td className={CN.gray}>{officeName}</td>
                                    <td className={CN.gray}>{deskCode}</td>
                                    <td className={CN.gray}>{format(new Date(date), DATES.reservationFormat)}</td>
                                    <td>
                                        <ReservationsTableTextarea
                                            confirmByAdmin={confirmByAdmin}
                                            id={id}
                                            notes={notes}
                                            disabled={true}
                                            placeholder="Note:"
                                            officeName={officeName}
                                            deskCode={deskCode}
                                            onUpdate={onUpdate}
                                        />
                                    </td>
                                    <td>
                                        {status === 'CONFIRMED' ? (
                                            <button className={CN['button-default']}>Confirmed</button>
                                        ) : (
                                            <button
                                                disabled={isLoading}
                                                onClick={() =>
                                                    openModal(
                                                        id,
                                                        deskCode,
                                                        officeName,
                                                        format(new Date(date), DATES.reservationFormat),
                                                    )
                                                }
                                                className={
                                                    isOpened === id ? CN['button-danger'] : CN['button-danger-inverted']
                                                }
                                            >
                                                Cancel
                                            </button>
                                        )}
                                    </td>
                                    {errorsUpdate && (
                                        <td colSpan="7">
                                            <span> {errorsUpdate.message} </span>
                                        </td>
                                    )}
                                    {errorsCancel && (
                                        <td colSpan="7">
                                            <span> {errorsCancel.message} </span>
                                        </td>
                                    )}
                                    {errorsMyReservationsUpdate && (
                                        <td colSpan="7">
                                            <span>{errorsMyReservationsUpdate.message}</span>
                                        </td>
                                    )}
                                    {errorsMyReservationsCancel && (
                                        <td colSpan="7">
                                            <span>{errorsMyReservationsCancel.message}</span>
                                        </td>
                                    )}
                                </tr>
                            ),
                        )}
                    </tbody>
                </table>
            )}

            <CancelReservationModal
                isModalShown={isModalShown}
                closeModal={closeModal}
                reservation={selectedReservation}
                onCancel={onCancel}
            />
        </div>
    );
};

ReservationsTable.defaultProps = {
    reservations: {},
    adminView: false,
};

ReservationsTable.propTypes = {
    reservations: PT.array,
    adminView: PT.bool,
};

export default ReservationsTable;
