import { format, parseISO } from 'date-fns';
import PT from 'prop-types';

import Avatar from 'components/common/Avatar/Avatar.component.jsx';

import CN from './ReserveTable.module.scss';

const ReserveTableRow = ({ employee, desk, date }) => {
    const avatarUri = employee && employee.photoBase64 ? `data:image/png;base64, ${employee.photoBase64}` : null;

    return (
        <tr>
            <td className={`${CN.fz16} ${CN.flex} ${CN.alignCenter}`}>
                {employee && (
                    <Avatar
                        className={CN.avatar}
                        url={avatarUri}
                        firstName={employee.firstName}
                        lastName={employee.lastName}
                        displayName={`${employee.firstName} ${employee.lastName}`}
                    />
                )}
                {employee.firstName} {employee.lastName}
            </td>
            <td className={`${CN.fz16} ${CN.gray} ${CN.verticalCenter}`}>{desk.code}</td>
            <td className={`${CN.fz16} ${CN.gray} ${CN.verticalCenter}`}>{format(parseISO(date), 'dd/MM/yyyy')}</td>
        </tr>
    );
};

ReserveTableRow.defaultProps = {
    options: {},
};

ReserveTableRow.propTypes = {
    employee: PT.shape({
        firstName: PT.string,
        lastName: PT.string,
        photoBase64: PT.string,
    }),
    desk: PT.object,
    selectedOffice: PT.string,
    date: PT.string,
};

export default ReserveTableRow;
