// NOTE: the process.env will be replaced with static object by Webpack

const DEFAULT_LOG_LEVEL = 2;

const env = process.env; // eslint-disable-line no-undef

const baseFeUrl = env.REACT_APP_FE_BASE_URL || env.PUBLIC_URL;
const baseBeUrl = env.REACT_APP_BE_BASE_URL;
const clientId = env.REACT_APP_CLIENT_ID;
const tenantId = env.REACT_APP_TENANT_ID;

const jestWorkerId = env.JEST_WORKER_ID;

const log = (() => {
    const level = env.REACT_APP_LOG_LEVEL;
    const parsed = Number.parseInt(level);

    if (Number.isFinite(parsed)) {
        return parsed;
    }

    if (!jestWorkerId) {
        // if not inside test, print the message in the browser console
        // eslint-disable-next-line no-console
        console.info('INVALID LOG LEVEL', level, 'USING', DEFAULT_LOG_LEVEL, 'INSTEAD');
    }

    return DEFAULT_LOG_LEVEL;
})();

const baseMsUrl = 'https://login.microsoftonline.com/';

// eslint-disable-next-line no-unused-vars
const graphMeV1 = 'https://graph.microsoft.com/v1.0/me';
const graphMeV2 = 'https://graph.microsoft.com/v2.0/me';

const apiType = {
    user: 'user',
    backend: 'backend',
};

export default Object.freeze({
    baseFeUrl,
    baseBeUrl,
    baseMsUrl,
    graphMeV1,
    graphMeV2,
    clientId,
    tenantId,
    log,
    jestWorkerId,
    apiType,
});
