import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PT from 'prop-types';

import Loader from 'components/common/Loader/Loader.component';
import useComponentVisible from 'hooks/useComponentVisible.hook';

import CN from './Autocomplete.module.scss';

const Autocomplete = ({ options = [], loading, placeholder, onSelect, emptyMessage, hasValue }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [selected, setSelected] = useState({ value: '', label: '' });
    const [search, setSearch] = useState('');
    const [optionsList, setOptionsList] = useState([]);
    const CONTAINER_CLASSES = classNames(CN.container, isComponentVisible && CN.open);

    useEffect(() => {
        options && setOptionsList(options);
    }, [options]);

    useEffect(() => {
        if (!hasValue) {
            setSearch('');
            setSelected({ value: '', label: '' });
        }
    }, [hasValue]);

    useEffect(() => {
        if (search === '') {
            setSelected({ value: '', label: '' });
            onSelect({ value: '', label: '' });
        }
    }, [search, onSelect]);

    const handleToggleOptions = (value = null) => {
        setIsComponentVisible((current) => value ?? !current);
    };

    const handleSearch = useCallback(
        (e) => {
            setSearch(e.target.value);
            const filtered = options?.filter(({ label }) => label.toLowerCase().includes(e.target.value.toLowerCase()));
            setOptionsList(filtered);
        },
        [options],
    );

    const handleSelected = useCallback(
        (value, label) => {
            setSelected({ value, label });
            setSearch(label);
            onSelect({ value, label });
            setIsComponentVisible(false);
        },
        [onSelect, setIsComponentVisible],
    );

    const list = useMemo(
        () =>
            optionsList?.length > 0 ? (
                optionsList.map(({ value, label }) => (
                    <li
                        key={value}
                        role="option"
                        aria-selected={selected.value === value}
                        value={value}
                        onClick={() => handleSelected(value, label)}
                    >
                        {label}
                    </li>
                ))
            ) : (
                <li className={CN.empty}>{emptyMessage ?? 'No match.'}</li>
            ),
        [optionsList, selected.value, handleSelected, emptyMessage],
    );

    return (
        <div ref={ref} className={CONTAINER_CLASSES}>
            <input value={search} onChange={handleSearch} placeholder={placeholder} onClick={handleToggleOptions} />
            {isComponentVisible && (
                <ul>
                    {loading ? (
                        <li className={CN.empty}>
                            <Loader />
                        </li>
                    ) : (
                        list
                    )}
                </ul>
            )}
        </div>
    );
};

Autocomplete.defaultProps = {
    options: [],
};

Autocomplete.propTypes = {
    options: PT.array.isRequired,
    loading: PT.bool,
    placeholder: PT.string,
    onSelect: PT.func,
    emptyMessage: PT.string,
    hasValue: PT.bool,
};

export default Autocomplete;
