const home = '/';
const reserve = '/reserve';
const reservations = '/reservations';
const scan = '/scan';
const reports = '/reports';

const NAV = Object.freeze({
    home,
    reserve,
    reservations,
    scan,
    reports,
});

export default NAV;
