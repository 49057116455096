import C from 'core/global.conf.js';

const OPEN = '[';
const CLOSE = ']';

const { error, warn, info, debug } = console;

const logger = (tag) => ({
    error(...args) {
        if (C.log > 0) {
            error(OPEN, tag, CLOSE, ...args);
        }
        return args.pop();
    },

    warn(...args) {
        if (C.log > 1) {
            warn(OPEN, tag, CLOSE, ...args);
        }
        return args.pop();
    },

    info(...args) {
        if (C.log > 2) {
            info(OPEN, tag, CLOSE, ...args);
        }
        return args.pop();
    },

    debug(...args) {
        if (C.log > 3) {
            debug(OPEN, tag, CLOSE, ...args);
        }
        return args.pop();
    },
});

export default logger;
