import { useAsync } from 'react-use';

import C from 'core/global.conf.js';
import useAccessToken from 'hooks/useAccessToken.hook.js';
import fetchFromBe from 'utils/fetchFromBe.util.js';

const TYPE = 'json';

const useGetMyCurrentReservations = () => {
    const { token } = useAccessToken(C.apiType.backend);

    const params = new URLSearchParams({});

    return useAsync(async () => {
        if (!token) {
            return;
        }
        const uri = `${C.baseBeUrl}/my-reservations/current`;
        return await fetchFromBe({ uri, token, type: TYPE, params });
    }, [token]);
};

export default useGetMyCurrentReservations;
