import { useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useToast } from 'context/ToastContext';

const MainToaster = () => {
    const toastState = useToast();
    // TODO: add custom toast for info from reservations
    useEffect(() => {
        if (toastState?.type === 'success') {
            toast.success(toastState.message, {
                duration: toastState.duration,
            });
        } else if (toastState?.type === 'error') {
            toast.error(toastState.message, {
                duration: toastState.duration,
            });
        }
    }, [toastState]);

    return <Toaster toastOptions={{ position: 'bottom-center' }} />;
};

export default MainToaster;
