import { Fragment, useCallback, useEffect, useState } from 'react';
import OFFICES from 'consts/offices/offices.const.js';
import PT from 'prop-types';

import CN from 'components/common/DeskPicker/DeskPicker.module.scss';
import Loader from 'components/common/Loader/Loader.component.jsx';
import SeatButton from 'components/common/SeatButton/SeatButton.component.jsx';
import useGetOfficeReservations from 'hooks/api/Reservations/useGetOfficeReservations.hook.js';

import SeatLabel from '../SeatLabel/SeatLabel.component';

const DeskPicker = ({
    chosenDate,
    locationId,
    office,
    officeId,
    selectedSeat,
    setSelectedSeat,
    employeeId,
    scanDisabled,
}) => {
    const [currentRoom, setCurrentRoom] = useState(null);

    const [hoveredButtonId, setHoveredButtonId] = useState(null);

    const { value: officeReservations, loading, getOfficeReservations } = useGetOfficeReservations();

    useEffect(() => {
        getOfficeReservations(locationId, officeId, chosenDate);
    }, [locationId, officeId, chosenDate, getOfficeReservations]);

    useEffect(() => {
        if (office?.id && officeReservations?.data?.length > 0) {
            let officeDesks = [...office.desks];
            const mappedDesks = officeDesks.reduce((acc, curr) => {
                return [
                    ...acc,
                    {
                        ...curr,
                        reservations: officeReservations.data.filter((res) => curr.id === res.desk?.id),
                    },
                ];
            }, []);
            OFFICES.forEach((officeMap) => {
                (officeMap?.offices ?? []).forEach((room) => {
                    if (room.name === office.name) {
                        const roomObject = {
                            roomMap: room,
                            roomBackendData: { ...office, desks: mappedDesks },
                        };
                        setCurrentRoom(roomObject);
                    }
                });
            });
        } else if (office?.id && officeReservations?.data?.length <= 0) {
            let officeDesks = [...office.desks];
            const mappedDesks = officeDesks;
            OFFICES.forEach((officeMap) => {
                (officeMap?.offices ?? []).forEach((room) => {
                    if (room.name === office.name) {
                        const roomObject = {
                            roomMap: room,
                            roomBackendData: { ...office, desks: mappedDesks },
                        };
                        setCurrentRoom(roomObject);
                    }
                });
            });
        }
    }, [office, officeReservations]);

    const hoverOverButton = useCallback(() => setHoveredButtonId(null), [setHoveredButtonId]);

    const hoverOutButton = useCallback(() => setHoveredButtonId(null), [setHoveredButtonId]);

    const seatsAndLabels =
        currentRoom?.roomBackendData?.desks?.length > 0 &&
        currentRoom.roomBackendData.desks.map((desk) => (
            <Fragment key={desk.id}>
                <SeatButton
                    desk={desk}
                    selectedSeat={selectedSeat}
                    setSelectedSeat={setSelectedSeat}
                    reservation={desk.reservation}
                    hoverOver={hoverOverButton}
                    hoverOut={hoverOutButton}
                    employeeId={employeeId}
                    scanDisabled={scanDisabled}
                />
                <SeatLabel key={`${desk.code} + ${desk.id}`} desk={desk} hoveredButtonId={hoveredButtonId} />
            </Fragment>
        ));

    const seats = loading ? (
        <Loader />
    ) : (
        currentRoom && (
            <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="100%" height="100%" className={CN.map}>
                <image href={currentRoom?.roomMap?.map} height="100px" width="100px" />
                {seatsAndLabels}
            </svg>
        )
    );

    return seats;
};

DeskPicker.propTypes = {
    office: PT.object,
    selectedSeat: PT.object || null,
    setSelectedSeat: PT.func,
    locationId: PT.string,
    officeId: PT.string,
    chosenDate: PT.arrayOf(PT.oneOfType([PT.instanceOf(Date), PT.string])),
    employeeId: PT.string,
    scanDisabled: PT.bool,
};

export default DeskPicker;
