import PT from 'prop-types';

import skopjeMapFullImage from 'assets/maps/skopjeMapFull.svg';

const SkopjeOfficeMap = ({ className }) => (
    <img className={className} src={skopjeMapFullImage} alt="Skopje Office Map" />
);

SkopjeOfficeMap.propTypes = {
    className: PT.string,
};

export default SkopjeOfficeMap;
