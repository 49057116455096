const currentOffice = (selectedLocation, locations, officeId) => {
    const currentLocation =
        locations?.data && Object.assign({}, ...locations.data.filter((location) => location.id === selectedLocation));
    const currentOffice =
        currentLocation?.offices &&
        Object.assign({}, ...currentLocation.offices.filter((office) => office.id === officeId));
    return currentOffice;
};

export default currentOffice;
