import { useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { useMsal } from '@azure/msal-react';

import logger from 'utils/logger.util.js';
import unignore from 'utils/unignore.util.js';

const L = logger('useLogIn');

const DEFAULT_LOGIN_TYPE = 'redirect';

const useLogIn = (type = DEFAULT_LOGIN_TYPE) => {
    const { instance } = useMsal();

    const [{ error, value }, login] = useAsyncFn(async () => {
        if (DEFAULT_LOGIN_TYPE !== type) {
            const message = `Unsupported login type: ${type}`;
            L.error(message);
            throw new Error(message);
        }
        return unignore('useLogIn', instance.loginRedirect({ scopes: ['user.read'] }));
    }, [instance, type]);

    return useMemo(
        () => ({
            login,
            loginError: error,
            loginResult: value,
        }),
        [login, error, value],
    );
};

export default useLogIn;
