import { isAndroid, isIOS } from 'react-device-detect';

import LoginLogo from 'components/common/LoginLogo/LoginLogo.component';

import appleSVG from '../../../assets/appStore.svg';
import googleSVG from '../../../assets/googlePlay.svg';

import CN from './MobileView.module.scss';

const MobileView = () => {
    return (
        <div className={CN.container}>
            <div className={CN.logoContainer}>
                <LoginLogo className={CN.logo} />
                <p className={CN.title}>By Melon, part of Kin+Carta</p>
            </div>

            <div className={CN.btnsOuter}>
                <p>
                    Please go to mobile app to have native mobile experience or download the app from the App Store or
                    Google Play
                </p>
                <div className={CN.btnContainer}>
                    <a href={isIOS ? 'https://apps.apple.com/' : '#'}>
                        <img src={appleSVG} alt="" />
                    </a>
                    <a href={isAndroid ? 'https://play.google.com/' : '#'}>
                        <img src={googleSVG} alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MobileView;
