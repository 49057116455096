import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'components/layout/App/App.component.jsx';
import vitals from 'core/vitals.js';

import 'styles/global.scss';

const root = document.getElementById('root');
const children = React.createElement(App);

ReactDOM.createRoot(root).render(children);
vitals();
