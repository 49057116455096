import { useMyReservation } from 'context/MyReservationContext';
import { sortBy } from 'lodash/fp';

import ReservationsTable from './ReservationsTable.component';

import 'react-tabs/style/react-tabs.css';

const Reservations = () => {
    let myReservations = useMyReservation();

    myReservations = sortBy(['date', 'deskCode'], myReservations);

    return <ReservationsTable reservations={myReservations} />;
};

Reservations.propTypes = {};

export default Reservations;
