import classNames from 'classnames';
import PT from 'prop-types';

import CN from 'components/common/SeatLabel/SeatLabel.module.scss';

//renders image tag and must be a child of svg tag
const SeatLabel = ({ desk: { code, x, y, id }, hoveredButtonId }) => {
    return (
        <svg
            key={code}
            viewBox="0 0 90 36"
            x={`${x + 10}px`}
            y={`${y - 25}px`}
            className={classNames({
                [CN.deskCode]: true,
                [CN.showDeskCode]: hoveredButtonId === id,
            })}
        >
            <rect x="0" y="0" width="18px" height="5px" />
            <text width="15px" height="5px" x="0px" y="0px" fill="black">
                <tspan fill="black">{code}</tspan>
            </text>
        </svg>
    );
};

SeatLabel.propTypes = {
    desk: PT.object,
    hoveredButtonId: PT.string,
};

export default SeatLabel;
