import { memo, useEffect, useState } from 'react';
import PT from 'prop-types';

import noConnection from '../../../assets/NoInternet.png';

import CN from './NoInternetScreen.module.scss';

const NoInternetScreen = (props) => {
    const [status, setStatus] = useState(true);

    useEffect(() => {
        function changeStatus() {
            setStatus(navigator.onLine);
        }
        window.addEventListener('online', changeStatus);
        window.addEventListener('offline', changeStatus);
        return () => {
            window.removeEventListener('online', changeStatus);
            window.removeEventListener('offline', changeStatus);
        };
    }, []);

    return status ? (
        ''
    ) : (
        <>
            <div className={CN.container}>
                <div className={CN.wrapper}>
                    <img className={CN.logo} src={noConnection} alt="offline" />
                    <p className={CN.text}>No network connection. Connect to the internet and try again</p>
                </div>
            </div>
        </>
    );
};

NoInternetScreen.propTypes = {
    children: PT.node,
};

export default memo(NoInternetScreen);
